/* eslint-disable no-undef */
import React from "react";
import classes from "./AgencyAbout.module.scss";

// components
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import AboutAgencyListItem from "../../../components/AboutAgencyListItem/AboutAgencyListItem";

import { useFetch } from "../../../hooks/useFetch";
import parse from "html-react-parser";
import Loader from "../../../containers/Loader";
function AgencyAbout() {
  const [loading, data1, ] = useFetch(`/content/one/page?lang=en&id=9`);

  if (loading) return <Loader />;

  return (
    <div className={` ${classes.agency__about} load-anim`}>
      <BreadCrumb
        list={[
          { title: "Bosh sahifa", path: "/" },
          { title: "Muassasa", path: "/agency" },
          { title: "Muassasa", path: "/about-agency" },
        ]}
      />
      <div className={`container ${classes.agency__about__inner}`}>
        <h2 className={classes.agency__about__title}>Muassasa</h2>

        <div className={classes.agency__about__wrapper}>
          <div className={classes.agency__about__left}>
            <img
              src={data1.data?.image}
              alt="img"
              width="500"
              height="400"
              className={classes.agency__about__img}
            />
          </div>


          <div className={classes.agency__about__right}>
            <ul className={classes.agency__about__list}>
              {data1.data ? (
                <AboutAgencyListItem text={parse(data1.data?.description)} />
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </div>

      <div className={`container ${classes.agency__about__bottom}`}>
        <h3 className={classes.agency__about__main}>
          Faoliyatning huquqiy asoslari
        </h3>

        <div className={classes.agency__about__holder}>
          <div className={classes.agency__about__card}>
            <p className={classes.agency__about__text}>
              O‘ZBEKISTON RESPUBLIKASI PREZIDENTINING “FUQAROLARNING HUQUQ VA ERKINLIKLARINI TA’MINLASH HAMDA HUQUQIY XIZMAT KO‘RSATISHDA ADLIYA ORGANLARI VA MUASSASALARI FAOLIYATI TO‘G‘RISIDA”GI 17.03.2022 yildagi PF-89-son FARMONI
            </p>

            <span className={classes.agency__about__info}>
              <a
                target="_blank"
                href="https://lex.uz/docs/-5914998"
                rel="noreferrer"
              >
              lex.uz/docs/-5914998
              </a>
            </span>
          </div>

          <div className={classes.agency__about__card}>
            <p className={classes.agency__about__text}>
              O‘ZBEKISTON RESPUBLIKASI PREZIDENTINING “INTELLEKTUAL MULK SOHASINI YANADA RIVOJLANTIRISHGA OID QO‘SHIMCHA CHORA-TADBIRLAR TO‘G‘RISIDA”GI 26.04.2022 yildagi PQ-221-son QARORI
            </p>

            <span className={classes.agency__about__info}>
              <a
                target="_blank"
                href="https://lex.uz/docs/-5987120"
                rel="noreferrer"
              >
              lex.uz/docs/-5987120
              </a>
            </span>
          </div>

          <div className={classes.agency__about__card}>
            <p className={classes.agency__about__text}>
              O‘ZBEKISTON RESPUBLIKASI PREZIDENTINING “INTELLEKTUAL MULK OBYEKTLARINI MUHOFAZA QILISH TIZIMINI TAKOMILLASHTIRISH CHORA-TADBIRLARI TO‘G‘RISIDA”GI 28.01.2021 yildagi PQ-4965-son QARORI
            </p>

            <span className={classes.agency__about__info}>
              <a
                target="_blank"
                href="https://lex.uz/docs/-5248263"
                rel="noreferrer"
              >
              lex.uz/docs/-5248263
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgencyAbout;
