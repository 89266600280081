import React from 'react'
import classes from './Regulations.module.scss'
import {Link} from 'react-router-dom'
import BreadCrumb from '../../components/BreadCrumb'

function Regulations() {
  return (
    <div className={` ${classes.regulations} load-anim`}>
      <BreadCrumb
        list={[
          { title: "Bosh sahifa", path: "/" },
          { title: "Me'yoriy huquqiy hujjatlar", path: "/regulations" },
        ]}
      />
      <div className={ `container ${classes.regulations_container} ` }>
        <h1>Me'yoriy huquqiy hujjatlar</h1>
        <div className={classes.regulations_item}>
          <div className={classes.service__regulations__left}>
            <Link to='/international-regulations' className={classes.service__regulations__link}>
              XALQARO HUJJATLAR
            </Link>

            <Link to='/laws-Uzbekistan' className={classes.service__regulations__link}>
             O‘ZBEKISTON RESPUBLIKASI QONUNLARI
            </Link>

            <Link to='/decrees-president' className={classes.service__regulations__link}>
            O‘ZBEKISTON RESPUBLIKASI PREZIDENT QARORLARI VA FARMONLARI
            </Link>

            <Link to='/cabinet-ministers' className={classes.service__regulations__link}>
              O'ZBEKISTON RESPUBLIKASI VAZIRLAR MAHKAMASINING QARORLARI
            </Link>

            <Link to='/industry-regulations' className={classes.service__regulations__link}>
              MA'MURIY REGLAMENTLAR VA QOIDALAR
            </Link> 
            <a href='https://lex.uz/docs/-4680944' className={classes.service__regulations__link}>
              TARIFLAR VA BOJLAR
            </a>
          </div>
          <div className={classes.service__regulations__right}>
            {/* <Link to='/documents-invalidated ' className={classes.service__regulations__link}>
            Документы утратившие силу
            </Link> */}


            {/* <Link to='/projects' className={classes.service__regulations__link}>
            Проекты
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Regulations