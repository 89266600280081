import React from "react";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import classes from "./ActivityIntellectual.module.scss";
import AboutAgencyListItem from "../../../components/AboutAgencyListItem/AboutAgencyListItem";
import ActivityIntellectualIcon from "../../../assets/icons/activity-intelect.svg";

function ActivityIntellectual() {
  const fakeData = Array(3).fill("2");

  return (
    <div className={` ${classes.activity__intellectual} load-anim`}>
      <BreadCrumb
        list={[ 
            {title: "Bosh sahifa", path: "/"}, 
            {title: "Faoliyat", path: "/activity"}, 
            {title: "Intellektual mulk", path: "/activity-intellectual"}
          ]}
      />

      <div className={`container ${classes.activity__intellectual__inner}`}>
        <h1 className={classes.activity__intellectual__title}>
          Intellektual mulk
        </h1>

        <div className={classes.activity__intellectual__wrapper}>
          <h4 className={classes.activity__intellectual__text}>
            Intellektual mulk — bu insonning ijodiy faoliyati natijalariga bo‘lgan huquqi.
          </h4>
        </div>

        <h4 className={classes.activity__intellectual__subtitle}>
          <a
            target="_blank"
            href="https://lex.uz/docs/111189"
            rel="noreferrer"
            className={classes.activity__intellectual__link}
          >
            O‘zbekiston Respublikasining Fuqarolik kodeksi
          </a>{" "}
          (164−modda) mulk huquqini shaxsning o‘ziga qarashli bo‘lgan mol−mulkka o‘z hohishi bilan va o‘z manfaatlarini ko‘zlab egalik qilish, undan foydalanish va uni tasarruf etish, shuningdek o‘zining mulk huquqini, kim tomonidan bo‘lmasin, har qanday buzishni bartaraf etishni talab qilish huquqi sifatida belgilaydi.
        </h4>

        <p className={classes.activity__intellectual__subtitle}>
          1) intellektual faoliyat natijalari: <br /> ilmiy, adabiy va san’at asarlari;
          eshittirish tashkilotlarining ijrolari, fonogrammalari va eshittirishlari; <br />
          elektron hisoblash mashinalari uchun dasturlar va ma’lumotlar bazalari; <br />  
          ixtirolar, foydali modellar, sanoat namunalari; <br />
          seleksiya yutuqlari; <br />
          oshkor etilmagan axborot, shu jumladan ishlab chiqarish sirlari (nou-xau); <br />
          2) fuqarolik muomalasi ishtirokchilarining, tovarlar, ishlar va xizmatlarning xususiy alomatlarini aks ettiruvchi vositalar: <br />
          firma nomlari; <br />
          tovar belgilari (xizmat ko‘rsatish belgilari); tovar kelib chiqqan joy nomlari; <br />
          3) ushbu Kodeksda yoki boshqa qonunlarda nazarda tutilgan hollarda intellektual faoliyatning boshqa natijalari <br />
          hamda fuqarolik muomalasi ishtirokchilarining, tovarlar va xizmatlarning xususiy alomatlarini aks ettiruvchi <b1 />
          vositalar»(<a  className={classes.activity__intellectual__link} href="https://lex.uz/docs/180552">O‘zR Fuqarolik kodeksining</a> 1031−moddasi). <br />
          Tarixan intellektual mulk ikki asosiy toifani ichiga oladi. Bular mualliflik huquqi va sanoat mulki.
        </p>
      </div>

      {/* <div className={`container ${classes.activity__intellectual__holder}`}>
        <div className={classes.activity__intellectual__left}>
          <h4 className={classes.activity__intellectual__intellectual}>
            Объекты интеллектуальной собственности
          </h4>

          <span className={classes.activity__intellectual__helper__text}>
            В собственности могут находиться объекты интеллектуальной
            собственности{" "}
            <a
              target="_blank"
              href="https://online.zakon.kz/Document/?doc_id=30421270&pos=214;-33#pos=214;-33"
              rel="noreferrer"
              className={classes.activity__intellectual__link}
            >
              (статья 169 ГК РУз).
            </a>{" "}
            К ним относятся:
          </span>
        </div>

        <div className={classes.activity__intellectual__right}>
          {fakeData && fakeData.length > 0
            ? fakeData.map((_, index) => (
                <div
                  key={index}
                  className={classes.activity__intellectual__block}
                >
                  <h3 className={classes.activity__intellectual__helper__title}>
                    Результаты интеллектуальной деятельности
                  </h3>

                  <AboutAgencyListItem text="Произведения науки, литературы и искусства" />

                  <AboutAgencyListItem text="Исполнения, фонограммы, передачи организаций эфирного или кабельного вещания" />

                  <AboutAgencyListItem text="Программы для электронных вычислительных машин и базы данных (далее - ЭВМ)" />
                </div>
              ))
            : ""}

          <div className={classes.activity__intellectual__bottom}>
            <img
              src={ActivityIntellectualIcon}
              className={classes.activity__intellectual__img}
              alt=""
            />

            <div>
              <span className={classes.activity__intellectual__helper__text}>
                Информационная система Интеллектал мулк
              </span>

              <a
                target="_blank"
                href="https://online.zakon.kz/Document/?doc_id=30421270&pos=214;-33#pos=214;-33"
                rel="noreferrer"
                className={classes.activity__intellectual__link}
              >
                baza.ima.uz
              </a>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default ActivityIntellectual;
