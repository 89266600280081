import React from "react";
import BreadCrumb from "../../../components/BreadCrumb";
import Loader from "../../../containers/Loader";
import { useFetch } from "../../../hooks/useFetch";
import classes from "./CabinetMinisters.module.scss";

function CabinetMinisters() {
  const [loading, data, error] = useFetch("/content/all/acts?lang=en");

  if (loading) return <Loader />;

  return (
    <div className={` ${classes.cabinet} load-anim`}>
      <BreadCrumb
        list={[
          { title: "Bosh sahifa", path: "/" },
          { title: "Me'yoriy huquqiy hujjatlar", path: "/regulations" },
          {
            title: "O'ZBEKISTON RESPUBLIKASI VAZIRLAR MAHKAMASINING QARORLARI",
            path: "/cabinet-ministers",
          },
        ]}
      />

      <div className={`container ${classes.cabinet__inner}`}>
        <h2 className={classes.cabinet__title}>
          O'ZBEKISTON RESPUBLIKASI VAZIRLAR MAHKAMASINING QARORLARI
        </h2>

        <div className={classes.cabinet__wrapper}>
          {data.data.map((item) => (
            <>
              {!!item.category_title &&
                item.category_title !== "" &&
                item.category_title[0]?.slug ===
                  "postanovlenie-kabineta-ministrov-respubliki-uzbekistan-ob-utverzhdenii-polozheniya-ob-agentstve-po-intellektualnoy-sobstvennosti-pri-ministerstve-yustitsii-respubliki-uzbekistan" && (
                  <a
                    className={classes.cabinet__link}
                    href={item.description.replace(/<\/?[^>]+(>|$)/g, "")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h4>{item.title}</h4>
                  </a>
                )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CabinetMinisters;
