import React from "react";
import classes from "./AgencyStructure.module.scss";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import SturctureImg from "../../../assets/images/structureMobile.jpg";

import { SRLWrapper } from "simple-react-lightbox";

function Structura() {
  return (
    <div className={` ${classes.agency__structure} load-anim`}>
      <BreadCrumb
        list={[
          { title: "Bosh sahifa", path: "/" },
          { title: "Muassasa", path: "/agency" },
          { title: "Muassasa tuzilishi", path: "/structura-agency" },
        ]}
      />
      <div className={`container ${classes.agency__structure__inner}`}>
        <div className={classes.structura}>
          <h2 className={classes.structura__title}>Muassasa tuzilishi</h2>

          <SRLWrapper>
            <img
              className={classes.agency__mobile__img}
              style={{ width: "100%", objectFit: "contain", height: "auto" }}
              src={SturctureImg}
              alt=""
            />
          </SRLWrapper>

          <div className={classes.structura__tree}>
            <div className={classes.structura__row}>
              <div className={classes.structura__element}>
                Ilmiy-texnik kengash
              </div>
              <div className={classes.structura__border}>
                Ixtiro va foydali modellar bo'limi
              </div>
              <div className={classes.structura__border}>
                Tovar belgilari va xizmat ko'rsatish belgilari bo'limi
              </div>
              <div className={classes.structura__border}>
                Geografik ko'rsatkichlar, tovarlar kelib chiqqan joy nomlari, sanoat namunalari va EHM uchun dastur va ma'lumotlar bazlari bo'limi
              </div>
            </div>

            <div className={classes.structura__row}>
              <div className={classes.structura__element}>
                Direktor
              </div>
              <div className={classes.structura__border}>
                Tashkiliy nazorat bo'limi
              </div>
              <div className={classes.structura__border}>
                Moliya-iqtisod bo'limi
              </div>
              <div className={classes.structura__border}>
                Ma'muriy-xo'jalik ishlari bo'yicha bosh maslahatchi
              </div>
              <div className={classes.structura__border}>
               Kadrlar bo'yicha bosh mutaxassis
              </div>
              <div className={classes.structura__border}>
                Bosh yuriskonsult
              </div>
            </div>

            <div className={classes.structura__row}>
              <div className={classes.structura__element}>
                Rahbar kotibi
              </div>
              <div className={classes.structura__border}>
                Direktor o'rinbosari
              </div>
              <div className={classes.structura__border}>
                Axborot resurs va texnologiyalar va innovatsiyalarni qo'llab quvvatlash markazlari bilan ishlash bo'limi
              </div>
              <div className={classes.structura__border}>Mualliflik huquqi obyektlarini deponentlash bo'limi</div>
              <div className={classes.structura__border}>
                Dasturiy va texnik ta'minot bo'limi
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Structura;
