import React, { useEffect, useState } from 'react'
import classes from './ActivityPatent.module.scss'
import PDF from '../../../assets/icons/ICON (4).png'
import Patents from '../../../components/Patents'
import BreadCrumb from '../../../components/BreadCrumb'
export default function ActivityPatent() {
    const obj = [
        {
            name:' Aliyev Yuriy Azaryevich',
            compName:'703034, Samarqand shahri, Frolova ko’chasi, 139-uy, 26-xonadon, Samarqand Patent Servis',
            registNum:'1',
            registDate:'01.03.1993',
            spacial:'SP 1',
            service:'SP 2',
            special2: 'SP 3',
            lang:'ruscha, inglizcha, o’zbekcha',
        },
        {
            name:'Azimova Sayyora Akilovna',
            compName:'100060, Toshkent shahri, S. Azimov ko’chasi, 79, 3-qavat, MCHJ «INTELLEСTUAL TECHNOLOGIES',
            registNum:'61',
            registDate:'15.08.2014',
            spacial:'SP 1',
            service:'SP 2',
            special2: 'SP 3',
            lang:'ruscha, inglizcha',
        },
        {
            name:'Badyonkina Liliya Ilyameevna',
            compName:'170132, O’zbekiston, Andijon shahri, 1-mikrorayon, 7-uy, 24-xonadon',
            registNum:'43',
            registDate:' 20.06.2006',
            spacial:'SP 1',
            service:'SP 2',
            special2: 'SP 3',
            lang:'ruscha, inglizcha',
        },
        {
            name:'Borzenko Andrey Andreyevich',
            compName:"100170, Toshkent shahri, Mirzo-Ulug'bek ko’chasi, 59-16.",
            registNum:'3',
            registDate:'05.03.1993',
            spacial:'SP 1',
            service:'SP 2',
            special2: 'SP 3',
            lang:'ruscha, inglizcha',
        },
        {
            name:'Djumaxojaev Aziz Zafarovich',
            compName:'100098, Toshkent shahri, Qora-Qamish ½ , 17-uy, 53-xonadon',
            registNum:'38',
            registDate:'28.02.2006',
            spacial:'SP 1',
            service:'SP 2',
            special2: 'SP 3',
            lang:'ruscha, inglizcha, nemischa',
        },
        {
            name:'Dobrochasov Yuriy Dmitriyevich',
            compName:'100098, Toshkent shahri, Qora-Qamish ½ , 17-uy, 53-xonadon',
            registNum:'8',
            registDate:'11.03.1993  ',
            spacial:'SP 1',
            service:'SP 2',
            special2: 'SP 3',
            lang:'ruscha, inglizcha, nemischa',
        },
        {
            name:'Fedorova Larisa Yurevna',
            compName:'100000, Toshkent shahri P, M-5, 52-uy, 29-xonadon, Akademshaharcha, F. Xodjaeva ko’ch, 33',
            registNum:'2',
            registDate:'04.03.1993',
            spacial:'SP 1',
            service:'SP 2',
            special2: 'SP 3',
            lang:'ruscha, inglizcha, o’zbekcha',
        },
        {
            name:'Finyutina Konic Svetlana Maksimovna',
            compName:'100077, Mirzo Ulugbek ko’chasi, 92-22, OOO "Patent ishlari',
            registNum:'4',
            registDate:'05.03.1993',
            spacial:'SP 1',
            service:'SP 2',
            special2: 'SP 3',
            lang:'ruscha, inglizcha, fransuzcha, nemischa, o’zbekcha',
        },
        {
            name:'Grankina Svetlana Valerevna',
            compName:'100011, O’zbekiston Respublikasi, Toshkent shahri, Navoi ko’chasi 16 a uy, ofis 420, MchJ «FIKON»',
            registNum:'57',
            registDate:'10.05.2013',
            spacial:'SP 1',
            service:'SP 2',
            special2: 'SP 3',
            lang:'ruscha, inglizcha',
        },
        {
            name:'Iskanderova Nodira Gayibovna',
            compName:'100011, O’zbekiston. Toshkent, Navoiy ko’chasi, 16-a uy, 420 xona, "FIKON” MChJ',
            registNum:'35',
            registDate:' 10.02.2006 ',
            spacial:'SP 1',
            service:'SP 2',
            special2: 'SP 3',
            lang:'ruscha, inglizcha, fransuzcha',
        },
        {
            name:' Islamova Lolaxon Miraxmatovna',
            compName:'100084, Toshkent shahri, Yunusobod tumani, Amir Temur ko’chasi, 107- B uy, Xalqaro biznes markaz, 6S-ofis-11, MCHJ "Legalmax Intellectual Property"',
            registNum:'60',
            registDate:'15.07.2013 ',
            spacial:'SP 1',
            service:'SP 2',
            special2: 'SP 3',
            lang:'ruscha, inglizcha, fransuzcha',
        },
        {
            name:'Kashirin Sergey Pavlovich',
            compName:'100047, O’zbekiston Respublikasi, Toshkent shahri, а/я 12046, MchJ «Askon Patent»',
            registNum:'55',
            registDate:'10.05.2013',
            spacial:'SP 1',
            service:'SP 2',
            special2: 'SP 3',
            lang:'ruscha, inglizcha',
        },
        {
            name:'Kiselev Anatoliy Semenovich',
            compName:'Samarqand shahri, «El» AJ innovatsiya firmasi',
            registNum:'12',
            registDate:'02.04.1993 ',
            spacial:'SP 1',
            service:'SP 2',
            special2: 'SP 3',
            lang:'ruscha, fransuzcha',
        }
    ]
     const [arr1,setArr1] = useState([])
     const [arr2,setArr2] = useState([])
    useEffect(()=>{
        let half = Math.ceil(obj.length / 2)
        setArr1(obj.slice(0,half))
        setArr2(obj.slice(half))
    },[])
    return(
        <div className={`${classes.ActivityPatent} load-anim`}>
             <BreadCrumb 
                list={[
                    { title: "Bosh sahifa", path: "/" },
                    { title: "Faoliyat", path: "/agency" },
                    { title: "Patent vakillari", path: "/activity-patent" },
                ]} />
            <div className={classes.ActivityPatent_inner}>
                 <div className={classes.ActivityPatent_inner_title}>
                      <h1>Patent vakillari</h1>
                      {/* <img src={PDF} alt=''/> */}
                 </div>
                 <div className={classes.ActivityPatent_inner_main}>
                     <div className={classes.ActivityPatent_inner_main_item}>
                            <Patents object={arr1}/>
                     </div>
                     <div className={classes.ActivityPatent_inner_main_item}>
                           <Patents object={arr2}/>
                     </div>
                 </div>
            </div>
        </div>
    )
    
}