import React from 'react'
import classes from './News.module.scss'
import BreadCrumb from '../../components/BreadCrumb'
import { Link } from 'react-router-dom'

function News() {
    return (
      <div className={` ${classes.news} load-anim`}>
        <BreadCrumb
          list={[
            { title: "Bosh sahifa", path: "/" },
            { title: "Yangiliklar", path: "/all-news" },
          ]}
        />
        <div className={`container ${classes.news__inner}`}>
          <h1 className={classes.news__title}>Yangiliklar</h1>

          <div className={classes.news__wrapper}>
            <div className={classes.news__left}>
              <Link className={classes.news__link} to="/all-news">
                Yangiliklar
              </Link>
              <Link className={classes.news__link} to="/press-release">
                Matbuot xabarlari
              </Link>

              <Link className={classes.news__link} to="/events">
                Tadbirlar
              </Link>
            </div>

            <Link className={classes.news__link} to="/ad">
              E'lonlar
            </Link>
          </div>
        </div>
      </div>
    );
}

export default News