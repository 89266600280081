const uz = {
    title: "O'zbekiston Respublikasi Adliya vazirligi huzuridagi 'Intellektual mulk markazi' davlat muassasasi",
    headerLinks: {
        agency: "Muassasa",
        activity: "Faoliyat",
        news: 'YANGILIKLAR',
        services: 'XIZMATLAR',
        regulations: "Me'yoriy huquqiy hujjatlar",
        informationService: 'axborot xizmati',
        contact: 'Bog`lanish'
    },
    titles: {
        ads: "Yangiliklar",
        services: 'Davlat xizmatlari',
        state: 'Davlat',
        interactive: 'Interaktiv',
        news: 'Yangiliklar',
        pressRelease: 'Matbuot xabari',
        meets: 'Voqealar',
        media: 'Media kutubxonasi',
        gallery: 'Foto galereya',
        videoGallery: 'Video galereya',
        viewAll: 'Hammasini ko`rish',
        area: "Texnologiyalar va innovatsiyalarni qo'llab-quvvatlash markazlari",
        useFullLinks: 'Foydali havolalar',
        onlineNow: 'Hozir onlayn:',
        users: 'foydalanuvchlar',
        subscribes: 'Bizning yangiliklarimizga obuna bo`ling',
        email: 'Elektron pochtangizni kiriting',
        follow: 'Obuna bo‘ling',
        acts: "Me'yoriy-huquqiy hujjatlar",
        infoService: 'Huquqiy hujjatlar',
        social: 'Ijtimoiy tarmoq',
        contacts: 'Aloqa'
    },
     // organization: 'Bo`ysunuvchi tashkilotlar',
    links: {
        agency: {
            aboutAgency: 'Muassasa haqida',
            structureAgency: 'Muassasa tuzilishi',
            managent: 'Rahbariyat',
            departments: 'Muassasa bo`limlari',
            technicalCouncil: 'Ilmiy-texnika kengashi',
            // boardAppeal: 'Apellyatsiya kengashi',
            // publicCounsil: 'Jamoatchilik kengashi',
            patentFund: 'Davlat patent fondi',
            // awards: 'Xodimlarning mukofotlari',
            requisites: ' Rekvizitlar',
            vacancy: 'Vakansiyalar',
            questions: 'Savol va Javoblar',
            contacts: 'Kontaktlar',
            collegue: 'Kollegiya'
        },
        activity: {
            intellectualProperty: 'Intellektual mulk',
            verifiedPatents: 'Patent vakillari',
            tqqm: "TIQQM",
            // internationalCooperation: 'Xalqaro hamkorlik',
            againstCorruption: 'Korruptsiyaga qarshi kurash',
            // arbitrage: 'Arbitraj amaliyoti',
            statistics: 'Statistika',
            tenders: 'E`lon va tenderlar',
            // awards: 'Arbitraj qarorlari',
            // licence: 'Litsenziyalash',
            // governMent: 'Elektron hukumat',
            brotherHood: 'Fuqarolarning murojaati',
            openSourses: "Ochiq ma'lumotlar",
            univiersities: 'Universitetlarda intellektual mulk siyosati'
        },
        services: {
            // inventions: 'Ixtirolar',
            // utility: 'Foydali modellar',
            // appelations: 'Tovar kelib chiqqan joy nomlari',
            // databases: 'Kompyuter dasturi va ma`lumotlar bazasi',
            // industrial: 'Sanoat namunalari',
            // achievments: 'Naslchilik yutuqlari',
            // serviceMarks: 'Savdo belgilari, xizmat ko`rsatish belgilari',
            // topologies: 'Integral mikrosxemalar topologiyalari',
            // register: 'Patent vakillarini ro`yxatdan o`tkazish',
            // attestetion: 'Patent vakiliga nomzodni attestatsiyadan o`tkazish',
            // carrying: 'Patent vakillarini attestatsiyadan o`tkazish'
        },
        acts: {
            internationalActs: 'Xalqaro hujjatlar',
            lawsUzb: "O'zbekiston Respublikasi Qonunlari",
            decrees: 'Prezidentning farmon va qarorlari',
            ministres: 'Vazirlar Mahkamasining qarorlari',
            industry: 'Reglamentlar va qoidalar',
            docs: 'Yaroqsiz bo`lgan hujjatlar',
            category: 'Tariflar va bojlar',
            projects: 'Loyihalar'
        },
        infoService: {
            performence: 'Nutq va protokollar bo`yicha qo`llanma',
            media: 'Ommaviy axborot vositalari vakillarini akkreditatsiya qilish',
            giwe: 'Musobaqalar',
            mediaPlan: 'Media-reja va press-relizlar',
            mediatec: 'Media kutubxonasi',
            editions: 'Nashrlar'
        },
        news: {
            news: 'Yangiliklar',
            pressRelease: 'Matbuot xabari',
            meets: 'Voqealar',
            // ads: "E'lon"
        }
    }
}

export default uz