import Test from './images/Mask Group (14).png'
import img1 from './images/Mask Group (9).png'
import img2 from './images/Mask Group (10).png'
import img3 from './images/Mask Group (11).png'
import img4 from './images/Mask Group (12).png'
import img5 from './images/Mask Group (16).png'
import img6 from './images/Mask Group (17).png'
import imges1 from './images/Mask Group (14).png'
import icon1 from './icons/Vector (49).png'
import icon2 from './icons/Vector (50).png'
import icon3 from './icons/Vector (51).png'
import icon4 from './icons/Vector (52).png'
import icon5 from './icons/Vector (53).png'
import icon6 from './icons/Vector (54).png'
import icon7 from './icons/'
import icon from './images/card1.png'

export const images =[
      {
       title:'news',
        img:img4,
        deg:0,
      },
      {
        title:'news',
        img:img4,
        deg:0,
      },
      {
        title:'news',
        img:img4,
        deg:0,
      },
      {
        title:'news',
        img:img4,
        deg:0,
      },
      {
        title:'news',
        img:img4,
        deg:0,
      },
      {
        title:'news',
        img:img4,
        deg:0,
      },
      {
        title:'news',
        img:img4,
        deg:0,
      },
      {
        title:'news',
        img:img4,
        deg:0,
      },
      {
        title:'press',
        img:img5,
        deg:0,
      },
      {
        title:'press',
        img:img5,
        deg:0,
      },
      {
        title:'press',
        img:img5,
        deg:0,
      },
      {
        title:'press',
        img:img5,
        deg:0,
      },
      {
        title:'press',
        img:img5,
        deg:0,
      },
      {
        title:'press',
        img:img5,
        deg:0,
      }, 
       {
        title:'press',
        img:img5,
        deg:0,
      },
      {
        title:'press',
        img:img5,
        deg:0,
      },
      {
        title:'event',
        img:img6,
        deg:0,
      },
      {
        title:'event',
        img:img6,
        deg:0,
      },
      {
        title:'event',
        img:img6,
        deg:0,
      },
      {
        title:'event',
        img:img6,
        deg:0,
      },
      {
        title:'event',
        img:img6,
        deg:0,
      },
      {
        title:'event',
        img:img6,
        deg:0,
      },
      {
        title:'event',
        img:img6,
        deg:0,
      },
      {
        title:'event',
        img:img6,
        deg:0,
      },
]
export const Slider2 = [
  {
    title:'madia',
    amount:true,
    page:[
      {
        page_item:[
        {
          page_item_big:[
            {
            date_big:'24 Февраль',
            text_1_big:'Определены приоритеты развития сферы интеллектуальной собственности',
          }
          ]
        },
        {
          page_item_small:[
            {
              date:'24 Февраль',
              text_1:'Определены приоритеты развития сферы интеллектуальной собственности',
            },
            {
              date:'24 Февраль',
              text_1:'Определены приоритеты развития сферы интеллектуальной собственности',
            },
          ]
        },
        ],
      },
      {        
        page_item:[
        {
          page_item_big:[
            {
               date_big:'24 Февраль',
               text_1_big:'Определены приоритеты развития сферы интеллектуальной собственности',
            }
          ]
        },
        {
          page_item_small:[
            {
              date:'24 Февраль',
              text_1:'Определены приоритеты развития сферы интеллектуальной собственности',
            },
            {
              date:'24 Февраль',
              text_1:'Определены приоритеты развития сферы интеллектуальной собственности',
            },
          ]
        },
      ],
    }
    ],
  },
  {
    title:'gallery',
    amount:false,
    page:[
      {
        page_item:[
        {
          page_item_big:[
            {
            date_big:'24 Февраль',
            text_1_big:'Определены приоритеты развития сферы интеллектуальной собственности',
          }
          ]
        },
        {
          page_item_small:[
            {
              date:'24 Февраль',
              text_1:'Определены приоритеты развития сферы интеллектуальной собственности',
            },
            {
              date:'24 Февраль',
              text_1:'Определены приоритеты развития сферы интеллектуальной собственности',
            },
            {
              date:'24 Февраль',
              text_1:'Определены приоритеты развития сферы интеллектуальной собственности',
            },
          ]
        },
        ],
      },
      {        
        page_item:[
        {
          page_item_big:[
            {
               date_big:'24 Февраль',
               text_1_big:'Определены приоритеты развития сферы интеллектуальной собственности',
            }
          ]
        },
        {
          page_item_small:[
            {
              date:'24 Февраль',
              text_1:'Определены приоритеты развития сферы интеллектуальной собственности',
            },
            {
              date:'24 Февраль',
              text_1:'Определены приоритеты развития сферы интеллектуальной собственности',
            },
            {
              date:'24 Февраль',
              text_1:'Определены приоритеты развития сферы интеллектуальной собственности',
            },
          ]
        },
      ],
    }
    ],
  },
  {
    title:'videoGallery',
    amount:true,
    page:[
      {
        page_item:[
        {
          page_item_big:[
            {
            date_big:'24 Февраль',
            text_1_big:'Определены приоритеты развития сферы интеллектуальной собственности',
          }
          ]
        },
        {
          page_item_small:[
            {
              date:'24 Февраль',
              text_1:'Определены приоритеты развития сферы интеллектуальной собственности',
            },
            {
              date:'24 Февраль',
              text_1:'Определены приоритеты развития сферы интеллектуальной собственности',
            },
          ]
        },
        ],
      },
      {        
        page_item:[
        {
          page_item_big:[
            {
               date_big:'24 Февраль',
               text_1_big:'Определены приоритеты развития сферы интеллектуальной собственности',
            }
          ]
        },
        {
          page_item_small:[
            {
              date:'24 Февраль',
              text_1:'Определены приоритеты развития сферы интеллектуальной собственности',
            },
            {
              date:'24 Февраль',
              text_1:'Определены приоритеты развития сферы интеллектуальной собственности',
            },
          ]
        },
      ],
    }
    ],
  },

]
export const banner_top =[
  {
    amount:false,
    page:[
      {
        page_item:[
        {
          page_item_big:[
            {
            date_big:'24 Февраль',
            text_1_big:'Определены приоритеты развития сферы интеллектуальной собственности',
          }
          ]
        },
        {
          page_item_small:[
            {
              date:'24 Февраль',
              text_1:'Определены приоритеты развития сферы интеллектуальной собственности',
            },
            {
              date:'24 Февраль',
              text_1:'Определены приоритеты развития сферы интеллектуальной собственности',
            },
            {
              date:'24 Февраль',
              text_1:'Определены приоритеты развития сферы интеллектуальной собственности',
            },
          ]
        },
        ],
      },
    ],
  },
]
export const REGIONS = [
  {
    title: "Toshkent",
    id: "12",
    color: "#FBD406",
    name:'Усмонов Алишер Тахировичь',
    position:'taxi',
    tel:'99989898989',
    email:'assaas@gmail.com',
    location:'Toshkent' 
  },
  {
    title: "Namangan",
    id: "6",
    color: "#3921C1",
    name:'Усмонов Алишер Тахировичь',
    position:'cooker',
    tel:'99989898989',
    email:'tttttt@gmail.com',
    location:'Namangan' 
  },
  {
    title: "Andijon",
    id: "1",
    color: "#F3562C",
    name:'Тахировичь',
    position:'Driver',
    tel:'88888888',
    email:'ffffff@gmail.com',
    location:'Andijon' 
  },
  {
    title: "Farg'ona",
    id: "4",
    color: "#2C40D0",
    name:'Usmon',
    position:'tester',
    tel:'99989898989',
    email:'eeeee@gmail.com',
    location:"Farg'ona" 
  },
  {
    title: "Sirdaryo",
    id: "8",
    color: "#2158DB",
    name:'Усмонов Алишер Тахировичь',
    position:'singer',
    tel:'99989898989',
    email:'kkkkkk@gmail.com',
    location:'Sirdaryo' 
  },
  {
    title: "Jizzax",
    id: "3",
    color: "#FF6542",
    name:'Odil',
    position:'fighter',
    tel:'99989898989',
    email:'pppppp@gmail.com',
    location:'Jizzax' 
  },
  {
    title: "Samarqand",
    color: "#25B5BD",
    id: "9",
    name:'Усмонов Алишер Тахировичь',
    position:'Dancer',
    tel:'99989898989',
    email:'vvvvvv@gmail.com',
    location:'Samarqand' 
  },
  {
    title: "Qashqadaryo",
    color: "#F3542D",
    id: "5",
    name:'Jack',
    position:'Doctor',
    tel:'99989898989',
    email:'qqqqqqq@gmail.com',
    location:'Qashqadaryo' 
  },
  {
    title: "Surxondaryo",
    id: "10",
    color: "#FBD406",
    name:'Усмонов Алишер Тахировичь',
    position:'front-end',
    tel:'99989898989',
    email:'eeeeee@gmail.com',
    location:'Surxondaryo' 
  },
  {
    title: "Toshkent viloyati",
    id: "11",
    color: "#FBD406",
    name:'Усмонов Алишер Тахировичь',
    position:'Dancer',
    tel:'99989898989',
    email:'gggggg@gmail.com',
    location:'Toshkent viloyati' 
  },
  {
    title: "Navoiy",
    id: "7",
    color: "#2158DB",
    name:'Усмонов Алишер Тахировичь',
    position:'cooker',
    tel:'99989898989',
    email:'uuuuuu@gmail.com',
    location:'Navoiy' 
  },
  {
    title: "Buxoro",
    id: "2",
    color: "#FCB11A",
    name:'Усмонов Алишер Тахировичь',
    position:'cooker',
    tel:'99989898989',
    email:'jjjjjj@gmail.com',
    location:'Buxoro' 
  },
  {
    title: "Xorazm",
    id: "13",
    color: "#FF3500",
    name:'Усмонов Алишер Тахировичь',
    position:'cooker',
    tel:'99989898989',
    email:'oooooo@gmail.com',
    location:'Xorazm' 
  },
  {
    title: "Qoraqalpog'iston",
    id: "14",
    color: "#22A5AC",
    name:'Усмонов Алишер Тахировичь',
    position:'cooker',
    tel:'99989898989',
    email:'eeeeeee@gmail.com',
    location:"Qoraqalpog'iston" 
  },
];

export const SUBMENU = [
  {
    name: "Агентство",
    id: "1",
    link: "/agency",
    child: [
      {
        title: "Обо агенстве",
        id: "1",
        forwardLink: "/about-agency",
      },
      {
        title: "Структура агенства",
        forwardLink: "/structura-agency",
        id: "2",
      },
      {
        title: "Руководство",
        forwardLink: "/guide-agency",
        id: "3",
      },
      {
        title: "Коллегия",
        forwardLink: "/collegue-agency",
        id: "4",
      },
      {
        title: "Отделы агентсво",
        forwardLink: "/departments-agency",
        id: "5",
      },
      {
        title: "Подведомственные организации",
        forwardLink: "/organizations-agency",
        id: "6",
      },
      {
        title: "Научно-технический совет",
        forwardLink: "/technical-agency",
        id: "7",
      },
      {
        title: "Апелляционный совет",
        forwardLink: "/appeal-agency",
        id: "8",
      },
      {
        title: "Общественный совет",
        forwardLink: "/public-council",
        id: "9",
      },
      {
        title: "Государственный патентный фонд",
        forwardLink: "/patent-fund",
        id: "10",
      },
      {
        title: "Награждение сотрудников",
        forwardLink: "/awards-agency",
        id: "11",
      },
      {
        title: "Реквизиты",
        forwardLink: "/requisite-agency",
        id: "12",
      },
      {
        title: "Вакансии",
        forwardLink: "/vacany-agency",
        id: "13",
      },
      {
        title: "Вопросы и ответы",
        forwardLink: "/question-answer",
        id: "14",
      },
      {
        title: "Контакты",
        forwardLink: "/contact-agency",
        id: "15",
      },
    ],
  },

  {
    name: "Деятельность",
    id: "2",
    link: "/activity",
    child: [
      {
        title: "Интеллектуальная собственность",
        forwardLink: "/activity-intellectual",
        id: "1",
      },
      {
        title: "Патентные поверенные",
        forwardLink: "/activity-patent",
        id: "2",
      },
      {
        title: "Международное сотрудничество",
        forwardLink: "/activity-cooperation",
        id: "3",
      },
      {
        title: "Борьба с коррупцией",
        forwardLink: "/activity-corruption",
        id: "4",
      },
      {
        title: "Судебная практика",
        forwardLink: "/activity-arbitrage",
        id: "5",
      },
      {
        title: "Статистика и аналитика",
        forwardLink: "/activity-analytics",
        id: "6",
      },
      {
        title: "Объявления и тендеры",
        forwardLink: "/activity-tenders",
        id: "7",
      },
      {
        title: "Открытые данные",
        forwardLink: "/activity-open-data",
        id: "8",
      },
      {
        title: "Арбитражные решения",
        forwardLink: "/activity-arbitration",
        id: "9",
      },
      {
        title: "Лицензирование",
        forwardLink: "/activity-licensing",
        id: "10",
      },
      {
        title: "Электронное правительство",
        forwardLink: "/activity-e-government",
        id: "11",
      },
      {
        title: "Обращение граждан",
        forwardLink: "/activity-appeal",
        id: "12",
      },
      {
        title: "Политика интеллектуальной собственности в университетах",
        forwardLink: "/activity-universities",
        id: "13",
      },
    ],
  },

  {
    name: "НОВОСТИ",
    id: "3",
    link: "/news",
    child: [
      {
        title: "Новости",
        forwardLink:'/all-news',
        id: "1",
      },
      {
        title: "Пресс-релиз",
        forwardLink:'/press-release',
        id: "2",
      },
      {
        title: "Мероприятия",
        forwardLink:'/events',
        id: "3",
      },
      {
        title: "Объявление",
        forwardLink:'/ad',
        id: "4",
      },
    ],

    
  },

  {
    name: "УСЛУГИ",
    id: "4",
    link: "/services",
    child: [
      {
        title: "Изобретения",
        forwardLink: "/inventions",
        id: "1",
      },
      {
        title: "Полезные модели",
        forwardLink: "/utility-models",
        id: "2",
      },
      {
        title: "Наименования мест происхождения товаров",
        forwardLink: "/services-appellations",
        id: "3",
      },
      {
        title: "Программа для ЭВМ и базы данных",
        forwardLink: "/services-programs",
        id: "4",
      },
      {
        title: "Промышленные образцы",
        forwardLink: "/services-design",
        id: "5",
      },
      {
        title: "Селекционные достижения",
        forwardLink: "/services-breeding",
        id: "6",
      },
      {
        title: "Товарные знаки,  знаки обслуживания",
        forwardLink: "/service-marks",
        id: "7",
      },
      {
        title: "Топологии интегральных микросхем",
        forwardLink: "/services-topologies",
        id: "8",
      },
      {
        title: "Регистрация патентных поверренных",
        forwardLink: "/patent-registeration",
        id: "9",
      },
      {
        title: "Проведение аттестации кандидата в патентные поверенные",
        forwardLink: "/attestation",
        id: "10",
      },
      {
        title: "Проведение аттестации патентных поверенных",
        forwardLink: "/patent-attestation",
        id: "11",
      },
    ],
  },

  {
    name: "НОРМАТИВНО-ПРАВОВЫЕ АКТЫ",
    id: "5",
    link: "/regulations",
    child: [
      {
        title: "Международные нормативные акты",
        forwardLink: "/international-regulations",
        id: "1",
      },
      {
        title: "Законы Республики Узбекистан",
        forwardLink: "/laws-Uzbekistan",
        id: "2",
      },
      {
        title: "Указы и постановления Президента",
        forwardLink: "/decrees-president",
        id: "3",
      },
      {
        title: "Постановления кабинета Министров",
        forwardLink: "/cabinet-ministers",
        id: "4",
      },
      {
        title: "Отраслевые нормативно-правовые акты",
        forwardLink: "/industry-regulations",
        id: "5",
      },
      {
        title: "Документы утратившие силу",
        forwardLink: "/documents-invalidated",
        id: "6",
      },
      {
        title: "Проекты",
        forwardLink: "/projects",
        id: "7",
      },
      {
        title: "Тарифы и пошлины",
        forwardLink: "/tariffs-duties",
        id: "8",
      },
    ],
  },

  {
    name: "ИНФОРМАЦИОННАЯ СЛУЖБА",
    id: "6",
    link: "/information-servise",
    child: [
      {
        title: "Выступление и протоколы руководство",
        forwardLink: "/informationServise-protocols",
        id: "1",
      },
      {
        title: "Медиа план",
        forwardLink: "/informationServise-releases",
        id: "2",
      },
      {
        title: "Конкурсы",
        forwardLink: "/informationServise-contests",
        id: "3",
      },
      {
        title: "Аккредитация представителей СМИ",
        forwardLink: "/informationServise-media",
        id: "4",
      },
      {
        title: "Медиатека",
        forwardLink: "/informationServise-library",
        id: "5",
      },
      {
        title: "Издания",
        forwardLink: "/informationServise-editions",
        id: "6",
      },
    ],
    menuChild:[
     {
     sub_name: "Соцсети",
    id: "7",
    child2: [
      {
        title: "Facebook",
        forwardLink: "/https://www.facebook.com",
        id: "1",
      },
      {
        title: "Telegram",
        forwardLink: "/@gulhay0",
        id: "2",
      },
      {
        title: "YouTube",
        forwardLink: "/https://www.youtube.com",
        id: "3",
      },
      {
        title: "Instagram",
        forwardLink: "/https://www.instagram.com",
        id: "4",
      },
      
    ],
     }
],
  },

 
];

export const openData = [
  {
    message:
      "Обращение иректора Агентства по интеллектуальной собственности при Министерстве юстиции Республики Узбекистан",
    id: "1",
  },
  {
    message:
      "Tovar belgisiga bo`lgan huquqlarni o`tkazish to`g`risidagi va ulardan foydalanish bo`yicha litsenziya shartnomalari haqida ma'lumot",
    id: "2",
  },
  {
    message: "Ro'yxatdan o'tkazilgan foydali modellar haqida ma'lumot",
    id: "3",
  },
  {
    message: "Ro'yxatdan o'tkazilgan tovar belgilari haqida ma'lumot",
    id: "4",
  },
  {
    message:
      "Ixtiroga bo`lgan huquqlarni o`tkazish to`g`risidagi va ulardan foydalanish bo`yicha litsenziya shartnomalari haqida ma'lumot",
    id: "5",
  },
  {
    message: "Ro'yxatdan o'tkazilgan ixtirolar haqida ma'lumot",
    id: "6",
  },
  {
    message:
      "Ma'lumotlar bazasiga bo`lgan huquqni o`zga shaxsga o`tkazish va barcha mulkiy huquqlardan to`liq/qisman voz kechish to`g`risidagi shartnomalar haqida ma'lumot",
    id: "7",
  },
  {
    message:
      "Seleksiya yutuqlariga bo`lgan huquqlarni o`tkazish to`g`risidagi va ulardan foydalanish bo`yicha litsenziya shartnomalari haqida ma'lumot",
    id: "8",
  },
  {
    message: "Patent vakillari",
    id: "9",
  },
  {
    message: "Patent bojlari va to'lovlari stavkalari",
    id: "10",
  },
  {
    message:
      "EHM dasturiga bo`lgan huquqni o`zga shaxsga o`tkazish va barcha mulkiy huquqlardan to`liq/qisman voz kechish to`g`risidagi shartnomalar haqida ma'lumot",
    id: "11",
  },
  {
    message:
      "Sanoat namunalari bo`lgan huquqlarni o`tkazish to`g`risidagi va ulardan foydalanish bo`yicha litsenziya shartnomalari haqida ma'lumo",
    id: "12",
  },
  {
    message:
      "Foydali model bo`lgan huquqlarni o`tkazish to`g`risidagi va ulardan foydalanish bo`yicha litsenziya shartnomalari haqida ma'lumot",
    id: "13",
  },
  {
    message: "Ro'yxatdan o'tgan sanoat namunalari bo'yicha ma'lumot",
    id: "14",
  },
  {
    message:
      "Intellektual mulk obyektlariga topshirilgan talabnomalar haqida ma'lumot",
    id: "15",
  },
  {
    message:
      "Intellektual mulk obyektlariga topshirilgan talabnomalar ekspertizasi haqida ma'lumot",
    id: "16",
  },
  {
    message:
      "Ro'yhatdan o'tkazilgan Intellektual mulk obyektlari haqida ma'lumot",
    id: "17",
  },
  {
    message:
      "O'zbekiston Respublikasi Adliya vazirligi huzuridagi Intellektual mulk agentligining bo'sh ish o'rinlari",
    id: "18",
  },
  {
    message:
      "Intellektual mulk obyektlariga topshirilgan talabnomalar haqida ma'lumot",
    id: "19",
  },
  {
    message:
      "Intellektual mulk obyektlariga topshirilgan talabnomalar ekspertizasi haqida ma'lumot",
    id: "20",
  },
  {
    message:
      "O'zbekiston Respublikasi Adliya vazirligi huzuridagi Intellektual mulk agentligining bo'sh ish o'rinlari",
    id: "21",
  },
  {
    message:
      "Jismоniy vа yuridik shахslаrning O`zbеkistоn Rеspublikаsi Adliya vazirligi huzuridagi Intеllеktuаl mulk аgеntligi rаhbаriyati tomonidan shахsiy qаbul kunlari",
    id: "22",
  },
  {
    message:
      "O'zbekiston Respublikasi Adliya vazirligi huzuridagi Intellektual mulk agentligining manzili haqida ma'lumot, shu jumladan ularning geolokatsion ma'lumotlari",
    id: "23",
  },
  {
    message: "Ro'yxatdan o'tkazilgan EHM uchun dasturlar haqida ma'lumot",
    id: "24",
  },
  {
    message: "Ro'yxatdan o'tkazilgan ma'lumotlar bazalari haqida ma'lumot",
    id: "25",
  },
  {
    message: "Ro'yxatdan o'tkazilgan seleksiya yutuqlari haqida ma'lumot",
    id: "26",
  },
];
export const CARDMENU = [
  {
    link: "ГОСУДАРСТВЕННЫЕ",
  },
  {
    link: "ИНТЕРАКТИВНЫЕ",
  },
];
export const LANG = ["Русский", "English", "Uzbek"];
export const CARDTEXT = [
  {
    part: "ГОСУДАРСТВЕННЫЕ",
    title: "Изобретения",
    text: "Обеспечение реализации единой государственной политики в области интеллектуальной собственности,",
    img:icon,
    link: 'https://my-ima-front-kaup.vercel.app/ServicesPrograms/1'
  },
  {
    part: "ГОСУДАРСТВЕННЫЕ",
    title: "Программы для ЭВМ и базы данных",
    text: "Обеспечение реализации единой государственной политики в области интеллектуальной собственности,",
    img:icon1,
    link: 'https://my-ima-front-kaup.vercel.app/ServicesPrograms/2'
  },
  {
    part: "ГОСУДАРСТВЕННЫЕ",
    title: "Товарные знаки, знаки обслуживания",
    text: "Обеспечение реализации единой государственной политики в области интеллектуальной собственности,",
    img:icon2,
    link: 'https://my-ima-front-kaup.vercel.app/ServicesPrograms/3'
  },
  {
    part: "ГОСУДАРСТВЕННЫЕ",
    title: "Полезные модели",
    text: "Обеспечение реализации единой государственной политики в области интеллектуальной собственности,",
    img:icon3,
    link: 'https://my-ima-front-kaup.vercel.app/ServicesPrograms/4'
  },
  {
    part: "ГОСУДАРСТВЕННЫЕ",
    title: "Промышленные образцы",
    text: "Обеспечение реализации единой государственной политики в области интеллектуальной собственности,",
    img:icon4,
    link: 'https://my-ima-front-kaup.vercel.app/ServicesPrograms/5'
  },
  {
    part: "ГОСУДАРСТВЕННЫЕ",
    title: "Селекционные достижение",
    text: "Обеспечение реализации единой государственной политики в области интеллектуальной собственности,",
    img:icon5,
    link: 'https://my-ima-front-kaup.vercel.app/ServicesPrograms/6'
  },
   {
    part: "ГОСУДАРСТВЕННЫЕ",
    title: "Топологии интегральных микросхем",
    text: "Обеспечение реализации единой государственной политики в области интеллектуальной собственности,",
    img:icon6,
    link: 'https://my-ima-front-kaup.vercel.app/ServicesPrograms/7'
  },
   {
    part: "ГОСУДАРСТВЕННЫЕ",
    title: "Наименования мест происхождения товаров",
    text: "Обеспечение реализации единой государственной политики в области интеллектуальной собственности,",
    img:icon,
    link: 'https://my-ima-front-kaup.vercel.app/ServicesPrograms/8'
  },
   {
    part: "ГОСУДАРСТВЕННЫЕ",
    title: "Географические указания",
    text: "Обеспечение реализации единой государственной политики в области интеллектуальной собственности,",
    img:icon,
    link: 'https://my-ima-front-kaup.vercel.app/ServicesPrograms/9'
  },
  //  {
  //   part: "ГОСУДАРСТВЕННЫЕ",
  //   title: "Изобретения",
  //   text: "Обеспечение реализации единой государственной политики в области интеллектуальной собственности,",
  //   img:icon5,
  // },
  {
    part: "ИНТЕРАКТИВНЫЕ",
    title: "Изобретения",
    text: "Обеспечение реализации единой государственной политики в области интеллектуальной собственности,",
    img:icon6,
  },
  {
    part: "ИНТЕРАКТИВНЫЕ",
    title: "Изобретения",
    text: "Обеспечение реализации единой государственной политики в области интеллектуальной собственности,",
    img:icon,
  },
  {
    part: "ИНТЕРАКТИВНЫЕ",
    title: "Изобретения",
    text: "Обеспечение реализации единой государственной политики в области интеллектуальной собственности,",
    img:icon1,
  },
  {
    part: "ИНТЕРАКТИВНЫЕ",
    title: "Изобретения",
    text: "Обеспечение реализации единой государственной политики в области интеллектуальной собственности,",
    img:icon2,
  },
  {
    part: "ИНТЕРАКТИВНЫЕ",
    title: "Изобретения",
    text: "Обеспечение реализации единой государственной политики в области интеллектуальной собственности,",
    img:icon3,
  },
  
]
export const concurse = [
  {
    id:'1',
    title: '1 Best IP-2019» на лучшие объекты интеллектуальной собственности',
    img: img1,
    date:'10 Января, 2019',
    child:[
      {
        text:`Агентством по интеллектуальной собственности Республики Узбекистан в целях стимулирования изобретательства и научно-технического творчества, повышения инновационной активности, содействия созданию новой техники, технологий и конкурентоспособной продукции путем выявления лучших объектов интеллектуальной собственности и поощрения их авторов объявляется конкурс «Best IP-2019» по номинациям: лучшее изобретение, лучшая полезная модель, лучший промышленный образец, лучшая программа для ЭВМ и база данных, лучшее селекционное достижение и автор лучшей программы для ЭВМ среди молодежи.
        На конкурс принимаются объекты интеллектуальной собственности - изобретения, полезные модели, промышленные образцы и селекционные достижения, на которые получены охранные документы Республики Узбекистан, являющиеся действующими, а также программные продукты, созданные отечественными разработчиками в течение последних 3 лет (2016-2018 гг.).
        По номинации «Автор лучшей программы для ЭВМ среди молодежи» возраст участников - до 40 лет.
        Документы на конкурс принимаются с 10 января по 12 апреля 2019 года.
        Результаты конкурса будут объявлены накануне 26 апреля - Международного дня интеллектуальной собственности.
        Заявки, получившие призовые места в ранее проведенных конкурсах, к рассмотрению в данном конкурсе не принимаются.`,
        img:img1,
      }
    ]
  },
  {
    id:'2',
    title:'2 Best IP-2019» на лучшие объекты интеллектуальной собственности',
    img:img2,
    date:'10 Января, 2019',
    child:[
      {
        text:`Агентством по интеллектуальной собственности Республики Узбекистан в целях стимулирования изобретательства и научно-технического творчества, повышения инновационной активности, содействия созданию новой техники, технологий и конкурентоспособной продукции путем выявления лучших объектов интеллектуальной собственности и поощрения их авторов объявляется конкурс «Best IP-2019» по номинациям: лучшее изобретение, лучшая полезная модель, лучший промышленный образец, лучшая программа для ЭВМ и база данных, лучшее селекционное достижение и автор лучшей программы для ЭВМ среди молодежи.
        На конкурс принимаются объекты интеллектуальной собственности - изобретения, полезные модели, промышленные образцы и селекционные достижения, на которые получены охранные документы Республики Узбекистан, являющиеся действующими, а также программные продукты, созданные отечественными разработчиками в течение последних 3 лет (2016-2018 гг.).
        По номинации «Автор лучшей программы для ЭВМ среди молодежи» возраст участников - до 40 лет.
        Документы на конкурс принимаются с 10 января по 12 апреля 2019 года.
        Результаты конкурса будут объявлены накануне 26 апреля - Международного дня интеллектуальной собственности.
        Заявки, получившие призовые места в ранее проведенных конкурсах, к рассмотрению в данном конкурсе не принимаются.`,
        img:Test,
      }
    ]
  },
  {
    id:'3',
    title:'3 Best IP-2019» на лучшие объекты интеллектуальной собственности',
    img:img3,
    date:'10 Января, 2019',
    child:[
      {
        text:`Агентством по интеллектуальной собственности Республики Узбекистан в целях стимулирования изобретательства и научно-технического творчества, повышения инновационной активности, содействия созданию новой техники, технологий и конкурентоспособной продукции путем выявления лучших объектов интеллектуальной собственности и поощрения их авторов объявляется конкурс «Best IP-2019» по номинациям: лучшее изобретение, лучшая полезная модель, лучший промышленный образец, лучшая программа для ЭВМ и база данных, лучшее селекционное достижение и автор лучшей программы для ЭВМ среди молодежи.
        На конкурс принимаются объекты интеллектуальной собственности - изобретения, полезные модели, промышленные образцы и селекционные достижения, на которые получены охранные документы Республики Узбекистан, являющиеся действующими, а также программные продукты, созданные отечественными разработчиками в течение последних 3 лет (2016-2018 гг.).
        По номинации «Автор лучшей программы для ЭВМ среди молодежи» возраст участников - до 40 лет.
        Документы на конкурс принимаются с 10 января по 12 апреля 2019 года.
        Результаты конкурса будут объявлены накануне 26 апреля - Международного дня интеллектуальной собственности.
        Заявки, получившие призовые места в ранее проведенных конкурсах, к рассмотрению в данном конкурсе не принимаются.`,
        img:Test
      }
    ]
  },
  {
    id:'4',
    title:'4 Best IP-2019» на лучшие объекты интеллектуальной собственности',
    img:img4,
    date:'10 Января, 2019',
    child:[
      {
        text:`Агентством по интеллектуальной собственности Республики Узбекистан в целях стимулирования изобретательства и научно-технического творчества, повышения инновационной активности, содействия созданию новой техники, технологий и конкурентоспособной продукции путем выявления лучших объектов интеллектуальной собственности и поощрения их авторов объявляется конкурс «Best IP-2019» по номинациям: лучшее изобретение, лучшая полезная модель, лучший промышленный образец, лучшая программа для ЭВМ и база данных, лучшее селекционное достижение и автор лучшей программы для ЭВМ среди молодежи.
        На конкурс принимаются объекты интеллектуальной собственности - изобретения, полезные модели, промышленные образцы и селекционные достижения, на которые получены охранные документы Республики Узбекистан, являющиеся действующими, а также программные продукты, созданные отечественными разработчиками в течение последних 3 лет (2016-2018 гг.).
        По номинации «Автор лучшей программы для ЭВМ среди молодежи» возраст участников - до 40 лет.
        Документы на конкурс принимаются с 10 января по 12 апреля 2019 года.
        Результаты конкурса будут объявлены накануне 26 апреля - Международного дня интеллектуальной собственности.
        Заявки, получившие призовые места в ранее проведенных конкурсах, к рассмотрению в данном конкурсе не принимаются.`,
      }
    ]
  },
]
export const  imgLibrary=[
  {
    id:'1',
    image:imges1,
    date:'24 Февраль',
    title:'Церемония награждения победителей конкурса на лучший объект интеллектуальной собственности “YANGI INTELLEKT-2013',
    child:[
      {img:img1},
      {img:img2},
      {img:img3},
      {img:img4},
      {img:img5},
      {img:img6},
    ]
  },
  {
    id:'2',
    image:imges1,
    date:'24 Февраль',
    title:'Церемония награждения победителей конкурса на лучший объект интеллектуальной собственности “YANGI INTELLEKT-2013',
    child:[
      {img:img1},
      {img:img2},
      {img:img3},
      {img:img4},
      {img:img5},
      {img:img6},
    ]
  },
]

export const FAQ = [
  {
    content: "Название раздела1 ",
    id: 1,
  },
   {
    content: "Название раздела 2",
    id: 2,
  },
   {
    content: "Название раздела3 ",
    id: 3,
  },
   {
    content: "Название раздела4 ",
    id: 4,
  },
   {
    content: "Название раздела 5",
    id: 5,
  },
   {
    content: "Название раздела6 ",
    id: 6,
  },
   {
    content: "Название раздела 7",
    id: 7,
  },
];

export const questionDropdown = [
  {
    text: "Можно ли подать электронную заявку на регистрацию товарного знака?",
    id: "1",
    content: [
      {
        title:
          "В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:",
        list_items: [
          "Заявление о регистрации знака в качестве товарного знака, наименования места происхождения товара и права использования наименования места происхождения товара",

          "Изображение знака, указанного в заявке",

          "Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии с международной классификацией товаров и услуг для регистрации товарных знаков",

          "Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект",
        ],
      },

      {
        title: "К заявлению необходимо приложить:",
        list_items: [
          
          "Подтверждение оплаты патентной пошлины за подачу заявки",

          "Подтверждение оплаты патентной пошлины за подачу заявки Доверенность заявителя в случае подачи заявки через патентного поверенного",

          "Документы, подтверждающие местонахождение заявителя в указанном географическом объекте и особенности производства товаров, связанные с природными условиями или иными факторами, характерными для данного географического объекта, либо с природными условиями и сочетанием этих факторов",

          "Документ, подтверждающий право иностранного заявителя на использование наименования места происхождения товара в стране происхождения товара",
        ],
      },
    ],
  },

  {
    text: "На что обратить внимание при подаче заявления?",
    id: "2",
    content: [
      {
        title:
          "В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:",
        list_items: [
          "Заявление о регистрации знака в качестве товарного знака, наименования места происхождения товара и права использования наименования места происхождения товара",

          "Изображение знака, указанного в заявке",

          "Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии с международной классификацией товаров и услуг для регистрации товарных знаков",

          "Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект",
        ],
      },

      {
        title: "К заявлению необходимо приложить:",
        list_items: [
          
          "Подтверждение оплаты патентной пошлины за подачу заявки",

          "Подтверждение оплаты патентной пошлины за подачу заявки Доверенность заявителя в случае подачи заявки через патентного поверенного",

          "Документы, подтверждающие местонахождение заявителя в указанном географическом объекте и особенности производства товаров, связанные с природными условиями или иными факторами, характерными для данного географического объекта, либо с природными условиями и сочетанием этих факторов",

          "Документ, подтверждающий право иностранного заявителя на использование наименования места происхождения товара в стране происхождения товара",
        ],
      },
    ],
  },

  {
    text: "Каков срок регистрации товарного знака?",
    id: "3",
    content: [
      {
        title:
          "В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:",
        list_items: [
          "Заявление о регистрации знака в качестве товарного знака, наименования места происхождения товара и права использования наименования места происхождения товара",

          "Изображение знака, указанного в заявке",

          "Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии с международной классификацией товаров и услуг для регистрации товарных знаков",

          "Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект",
        ],
      },

      {
        title: "К заявлению необходимо приложить:",
        list_items: [
          
          "Подтверждение оплаты патентной пошлины за подачу заявки",

          "Подтверждение оплаты патентной пошлины за подачу заявки Доверенность заявителя в случае подачи заявки через патентного поверенного",

          "Документы, подтверждающие местонахождение заявителя в указанном географическом объекте и особенности производства товаров, связанные с природными условиями или иными факторами, характерными для данного географического объекта, либо с природными условиями и сочетанием этих факторов",

          "Документ, подтверждающий право иностранного заявителя на использование наименования места происхождения товара в стране происхождения товара",
        ],
      },
    ],
  },

  {
    text: "Сколько стоит государственная пошлина за регистрацию товарного знака?",
    id: "4",
    content: [
      {
        title:
          "В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:",
        list_items: [
          "Заявление о регистрации знака в качестве товарного знака, наименования места происхождения товара и права использования наименования места происхождения товара",

          "Изображение знака, указанного в заявке",

          "Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии с международной классификацией товаров и услуг для регистрации товарных знаков",

          "Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект",
        ],
      },

      {
        title: "К заявлению необходимо приложить:",
        list_items: [
          
          "Подтверждение оплаты патентной пошлины за подачу заявки",

          "Подтверждение оплаты патентной пошлины за подачу заявки Доверенность заявителя в случае подачи заявки через патентного поверенного",

          "Документы, подтверждающие местонахождение заявителя в указанном географическом объекте и особенности производства товаров, связанные с природными условиями или иными факторами, характерными для данного географического объекта, либо с природными условиями и сочетанием этих факторов",

          "Документ, подтверждающий право иностранного заявителя на использование наименования места происхождения товара в стране происхождения товара",
        ],
      },
    ],
  },

  {
    text: "Сколько товарных знаков необходимо зарегистрировать через одну заявку?",
    id: "5",
    content: [
      {
        title:
          "В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:",
        list_items: [
          "Заявление о регистрации знака в качестве товарного знака, наименования места происхождения товара и права использования наименования места происхождения товара",

          "Изображение знака, указанного в заявке",

          "Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии с международной классификацией товаров и услуг для регистрации товарных знаков",

          "Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект",
        ],
      },

      {
        title: "К заявлению необходимо приложить:",
        list_items: [
          
          "Подтверждение оплаты патентной пошлины за подачу заявки",

          "Подтверждение оплаты патентной пошлины за подачу заявки Доверенность заявителя в случае подачи заявки через патентного поверенного",

          "Документы, подтверждающие местонахождение заявителя в указанном географическом объекте и особенности производства товаров, связанные с природными условиями или иными факторами, характерными для данного географического объекта, либо с природными условиями и сочетанием этих факторов",

          "Документ, подтверждающий право иностранного заявителя на использование наименования места происхождения товара в стране происхождения товара",
        ],
      },
    ],
  },

  {
    text: "Как заполнить заявку на торговую марку?",
    id: "6",
    content: [
      {
        title:
          "В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:",
        list_items: [
          "Заявление о регистрации знака в качестве товарного знака, наименования места происхождения товара и права использования наименования места происхождения товара",

          "Изображение знака, указанного в заявке",

          "Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии с международной классификацией товаров и услуг для регистрации товарных знаков",

          "Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект",
        ],
      },

      {
        title: "К заявлению необходимо приложить:",
        list_items: [
          
          "Подтверждение оплаты патентной пошлины за подачу заявки",

          "Подтверждение оплаты патентной пошлины за подачу заявки Доверенность заявителя в случае подачи заявки через патентного поверенного",

          "Документы, подтверждающие местонахождение заявителя в указанном географическом объекте и особенности производства товаров, связанные с природными условиями или иными факторами, характерными для данного географического объекта, либо с природными условиями и сочетанием этих факторов",

          "Документ, подтверждающий право иностранного заявителя на использование наименования места происхождения товара в стране происхождения товара",
        ],
      },
    ],
  },

  {
    text: "Какую ошибку они часто допускают при подаче заявления?",
    id: "7",
    content: [
      {
        title:
          "В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:",
        list_items: [
          "Заявление о регистрации знака в качестве товарного знака, наименования места происхождения товара и права использования наименования места происхождения товара",

          "Изображение знака, указанного в заявке",

          "Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии с международной классификацией товаров и услуг для регистрации товарных знаков",

          "Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект",
        ],
      },

      {
        title: "К заявлению необходимо приложить:",
        list_items: [
          
          "Подтверждение оплаты патентной пошлины за подачу заявки",

          "Подтверждение оплаты патентной пошлины за подачу заявки Доверенность заявителя в случае подачи заявки через патентного поверенного",

          "Документы, подтверждающие местонахождение заявителя в указанном географическом объекте и особенности производства товаров, связанные с природными условиями или иными факторами, характерными для данного географического объекта, либо с природными условиями и сочетанием этих факторов",

          "Документ, подтверждающий право иностранного заявителя на использование наименования места происхождения товара в стране происхождения товара",
        ],
      },
    ],
  },

  {
    text: "Как я узнаю, что запрос отправлен Агентством, в случае возникновения вопроса по заявке?",
    id: "8",
    content: [
      {
        title:
          "В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:",
        list_items: [
          "Заявление о регистрации знака в качестве товарного знака, наименования места происхождения товара и права использования наименования места происхождения товара",

          "Изображение знака, указанного в заявке",

          "Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии с международной классификацией товаров и услуг для регистрации товарных знаков",

          "Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект",
        ],
      },

      {
        title: "К заявлению необходимо приложить:",
        list_items: [
          
          "Подтверждение оплаты патентной пошлины за подачу заявки",

          "Подтверждение оплаты патентной пошлины за подачу заявки Доверенность заявителя в случае подачи заявки через патентного поверенного",

          "Документы, подтверждающие местонахождение заявителя в указанном географическом объекте и особенности производства товаров, связанные с природными условиями или иными факторами, характерными для данного географического объекта, либо с природными условиями и сочетанием этих факторов",

          "Документ, подтверждающий право иностранного заявителя на использование наименования места происхождения товара в стране происхождения товара",
        ],
      },
    ],
  },

  {
    text: "На что следует обратить внимание, чтобы убедиться, что товарный знак зарегистрирован?",
    id: "9",
    content: [
      {
        title:
          "В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:",
        list_items: [
          "Заявление о регистрации знака в качестве товарного знака, наименования места происхождения товара и права использования наименования места происхождения товара",

          "Изображение знака, указанного в заявке",

          "Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии с международной классификацией товаров и услуг для регистрации товарных знаков",

          "Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект",
        ],
      },

      {
        title: "К заявлению необходимо приложить:",
        list_items: [
          
          "Подтверждение оплаты патентной пошлины за подачу заявки",

          "Подтверждение оплаты патентной пошлины за подачу заявки Доверенность заявителя в случае подачи заявки через патентного поверенного",

          "Документы, подтверждающие местонахождение заявителя в указанном географическом объекте и особенности производства товаров, связанные с природными условиями или иными факторами, характерными для данного географического объекта, либо с природными условиями и сочетанием этих факторов",

          "Документ, подтверждающий право иностранного заявителя на использование наименования места происхождения товара в стране происхождения товара",
        ],
      },
    ],
  },

  {
    text: "Какие виды товарных знаков существуют?",
    id: "10",
    content: [
      {
        title:
          "В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:",
        list_items: [
          "Заявление о регистрации знака в качестве товарного знака, наименования места происхождения товара и права использования наименования места происхождения товара",

          "Изображение знака, указанного в заявке",

          "Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии с международной классификацией товаров и услуг для регистрации товарных знаков",

          "Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект",
        ],
      },

      {
        title: "К заявлению необходимо приложить:",
        list_items: [
          
          "Подтверждение оплаты патентной пошлины за подачу заявки",

          "Подтверждение оплаты патентной пошлины за подачу заявки Доверенность заявителя в случае подачи заявки через патентного поверенного",

          "Документы, подтверждающие местонахождение заявителя в указанном географическом объекте и особенности производства товаров, связанные с природными условиями или иными факторами, характерными для данного географического объекта, либо с природными условиями и сочетанием этих факторов",

          "Документ, подтверждающий право иностранного заявителя на использование наименования места происхождения товара в стране происхождения товара",
        ],
      },
    ],
  },
];
 export const newobj = [
  {
      part:2017,
      title:'2017 Участие сотрудников АИС РУз в судебных заседаниях',
      parent:[
          {
              parchildhead:[
                  {child:'АИС РУЗ'},
                  {child:'Изобретения'},
                  {child:'Полезные модели'},
                  {child:'Промышленные образцы'},
                  {child:'Товарные знаки'},
                  {child:'Селекционные достижение'},
                  {child:'Всего'},
              ],    
              parchild:[
                  {child:'Ответчик'},
                  {child:'-'},
                  {child:'-'},
                  {child:2},
                  {child:4},
                  {child:'-'},
                  {child:6},
              ],
          },
          {
              parchild:[
                  {child:'Третье лицо'},
                  {child:3},
                  {child:8},
                  {child:'-'},
                  {child:2},
                  {child:1},
                  {child:'-'},
              ],
          },
          {
              parchild:[
                  {child:'Итого'},
                  {child:3},
                  {child:8},
                  {child:2},
                  {child:6},
                  {child:1},
                  {child:6},
              ]
          },
      ],
  },
  {
      part:2018,
      title:'2018 Участие сотрудников АИС РУз в судебных заседаниях',
      items:[
          {id:1},
          {id:2},
          {id:3},
          {id:4},
          {id:5},
          {id:6},
          {id:7},
      ],
      parent:[
          {
              parchildhead:[
                  {child:'АИС РУЗ'},
                  {child:'Изобретения'},
                  {child:'Полезные модели'},
                  {child:'Промышленные образцы'},
                  {child:'Товарные знаки'},
                  {child:'Селекционные достижение'},
                  {child:'Всего'},
              ],    
              parchild:[
                  {child:'Ответчик'},
                  {child:'-'},
                  {child:'-'},
                  {child:2},
                  {child:4},
                  {child:'-'},
                  {child:6},
              ],
          },
          {
              parchild:[
                  {child:'Третье лицо'},
                  {child:3},
                  {child:8},
                  {child:'-'},
                  {child:2},
                  {child:1},
                  {child:'-'},
              ],
          },
          {
              parchild:[
                  {child:'Итого'},
                  {child:3},
                  {child:8},
                  {child:2},
                  {child:6},
                  {child:1},
                  {child:6},
              ],

          },
      ],


  },
  {
      part:2019,
      title:'2019 Участие сотрудников АИС РУз в судебных заседаниях',
      parent:[
          {
              parchildhead:[
                  {child:'АИС РУЗ'},
                  {child:'Изобретения'},
                  {child:'Полезные модели'},
                  {child:'Промышленные образцы'},
                  {child:'Товарные знаки'},
                  {child:'Селекционные достижение'},
                  {child:'Всего'},
              ],    
              parchild:[
                  {child:'Ответчик'},
                  {child:'-'},
                  {child:'-'},
                  {child:2},
                  {child:4},
                  {child:'-'},
                  {child:6},
              ],
          },
          {
              parchild:[
                  {child:'Третье лицо'},
                  {child:3},
                  {child:8},
                  {child:'-'},
                  {child:2},
                  {child:1},
                  {child:'-'},
              ],
          },
          {
              parchild:[
                  {child:'Итого'},
                  {child:3},
                  {child:8},
                  {child:2},
                  {child:6},
                  {child:1},
                  {child:6},
              ],

          },
      ],
  },
  {
      part:2020,
      title:'2020 Участие сотрудников АИС РУз в судебных заседаниях',
      parent:[
          {
              parchildhead:[
                  {child:'АИС РУЗ'},
                  {child:'Изобретения'},
                  {child:'Полезные модели'},
                  {child:'Промышленные образцы'},
                  {child:'Товарные знаки'},
                  {child:'Селекционные достижение'},
                  {child:'Всего'},
              ],    
              parchild:[
                  {child:'Ответчик'},
                  {child:'-'},
                  {child:'-'},
                  {child:2},
                  {child:4},
                  {child:'-'},
                  {child:6},
              ],
          },
          {
              parchild:[
                  {child:'Третье лицо'},
                  {child:3},
                  {child:8},
                  {child:'-'},
                  {child:2},
                  {child:1},
                  {child:'-'},
              ],
          },
          {
              parchild:[
                  {child:'Итого'},
                  {child:3},
                  {child:8},
                  {child:2},
                  {child:6},
                  {child:1},
                  {child:6},
              ],

          },
      ],
  },
  {
      part:2021,
      title:'2021 Участие сотрудников АИС РУз в судебных заседаниях',
      parent:[
          {
              parchildhead:[
                  {child:'АИС РУЗ'},
                  {child:'Изобретения'},
                  {child:'Полезные модели'},
                  {child:'Промышленные образцы'},
                  {child:'Товарные знаки'},
                  {child:'Селекционные достижение'},
                  {child:'Всего'},
              ],    
              parchild:[
                  {child:'Ответчик'},
                  {child:'-'},
                  {child:'-'},
                  {child:2},
                  {child:4},
                  {child:'-'},
                  {child:6},
              ],
          },
          {
              parchild:[
                  {child:'Третье лицо'},
                  {child:3},
                  {child:8},
                  {child:'-'},
                  {child:2},
                  {child:1},
                  {child:'-'},
              ],
          },
          {
              parchild:[
                  {child:'Итого'},
                  {child:3},
                  {child:8},
                  {child:2},
                  {child:6},
                  {child:1},
                  {child:6},
              ],

          },
      ],
  },
]
export const newobjmedia = [
  {
      part:2017,
      title:'2017 Участие сотрудников АИС РУз в судебных заседаниях',
      parent:[
          {
            parchild:[
              {
                child1:'АИС РУЗ',
                child:'Ответчик'
              },
              {
                child1:'Изобретения',
                child:3
              },
              {
                child1:'Полезные модели',
                child:8
              },
              {
                child1:'Промышленные образцы',
                child:'-'
              },
              {
                child1:'Товарные знаки',
                child:2
              },
              {
                child1:'Селекционные достижение',
                child:1
              },
              {
                child1:'Всего',
                child:'-'
              },
          ],
          },
          {
            parchild:[
              {
                child1:'АИС РУЗ',
                child:'Третье лицо'
              },
              {
                child1:'Изобретения',
                child:3
              },
              {
                child1:'Полезные модели',
                child:8
              },
              {
                child1:'Промышленные образцы',
                child:'-'
              },
              {
                child1:'Товарные знаки',
                child:2
              },
              {
                child1:'Селекционные достижение',
                child:1
              },
              {
                child1:'Всего',
                child:'-'
              },
          ],
          },
          {
            parchild:[
              {
                child1:'АИС РУЗ',
                child:'Итого'
              },
              {
                child1:'Изобретения',
                child:3
              },
              {
                child1:'Полезные модели',
                child:8
              },
              {
                child1:'Промышленные образцы',
                child:'-'
              },
              {
                child1:'Товарные знаки',
                child:2
              },
              {
                child1:'Селекционные достижение',
                child:1
              },
              {
                child1:'Всего',
                child:'-'
              },
          ],
          },
      ],
  },
  {
      part:2018,
      title:'2018 Участие сотрудников АИС РУз в судебных заседаниях',
      items:[
          {id:1},
          {id:2},
          {id:3},
          {id:4},
          {id:5},
          {id:6},
          {id:7},
      ],
      parent:[
          {

            parchild:[
              {
                child:'Ответчик',
                child:'Третье лицо'
              },
              {
                child1:'Изобретения',
                child:3
              },
              {
                child1:'Полезные модели',
                child:8
              },
              {
                child1:'Промышленные образцы',
                child:'-'
              },
              {
                child1:'Товарные знаки',
                child:2
              },
              {
                child1:'Селекционные достижение',
                child:1
              },
              {
                child1:'Всего',
                child:'-'
              },
          ],
          },
          {
            parchild:[
              {
                child1:'АИС РУЗ',
                child:'Третье лицо'
              },
              {
                child1:'Изобретения',
                child:3
              },
              {
                child1:'Полезные модели',
                child:8
              },
              {
                child1:'Промышленные образцы',
                child:'-'
              },
              {
                child1:'Товарные знаки',
                child:2
              },
              {
                child1:'Селекционные достижение',
                child:1
              },
              {
                child1:'Всего',
                child:'-'
              },
          ],
          },
          {
            parchild:[
              {
                child1:'АИС РУЗ',
                child:'Итого'
              },
              {
                child1:'Изобретения',
                child:3
              },
              {
                child1:'Полезные модели',
                child:8
              },
              {
                child1:'Промышленные образцы',
                child:'-'
              },
              {
                child1:'Товарные знаки',
                child:2
              },
              {
                child1:'Селекционные достижение',
                child:1
              },
              {
                child1:'Всего',
                child:'-'
              },
          ],

          },
      ],


  },
  {
      part:2019,
      title:'2019 Участие сотрудников АИС РУз в судебных заседаниях',
      parent:[
          {
            parchild:[
              {
                child1:'АИС РУЗ',
                child:'Ответчик'
              },
              {
                child1:'Изобретения',
                child:3
              },
              {
                child1:'Полезные модели',
                child:8
              },
              {
                child1:'Промышленные образцы',
                child:'-'
              },
              {
                child1:'Товарные знаки',
                child:2
              },
              {
                child1:'Селекционные достижение',
                child:1
              },
              {
                child1:'Всего',
                child:'-'
              },
          ],
          },
          {
            parchild:[
              {
                child1:'АИС РУЗ',
                child:'Третье лицо'
              },
              {
                child1:'Изобретения',
                child:3
              },
              {
                child1:'Полезные модели',
                child:8
              },
              {
                child1:'Промышленные образцы',
                child:'-'
              },
              {
                child1:'Товарные знаки',
                child:2
              },
              {
                child1:'Селекционные достижение',
                child:1
              },
              {
                child1:'Всего',
                child:'-'
              },
          ],
          },
          {
            parchild:[
              {
                child1:'АИС РУЗ',
                child:'Итого'
              },
              {
                child1:'Изобретения',
                child:3
              },
              {
                child1:'Полезные модели',
                child:8
              },
              {
                child1:'Промышленные образцы',
                child:'-'
              },
              {
                child1:'Товарные знаки',
                child:2
              },
              {
                child1:'Селекционные достижение',
                child:1
              },
              {
                child1:'Всего',
                child:'-'
              },
          ],

          },
      ],
  },
  {
      part:2020,
      title:'2020 Участие сотрудников АИС РУз в судебных заседаниях',
      parent:[
          {
            parchild:[
              {
                child1:'АИС РУЗ',
                child:'Ответчик'
              },
              {
                child1:'Изобретения',
                child:3
              },
              {
                child1:'Полезные модели',
                child:8
              },
              {
                child1:'Промышленные образцы',
                child:'-'
              },
              {
                child1:'Товарные знаки',
                child:2
              },
              {
                child1:'Селекционные достижение',
                child:1
              },
              {
                child1:'Всего',
                child:'-'
              },
          ],
          },
          {
            parchild:[
              {
                child1:'АИС РУЗ',
                child:'Третье лицо'
              },
              {
                child1:'Изобретения',
                child:3
              },
              {
                child1:'Полезные модели',
                child:8
              },
              {
                child1:'Промышленные образцы',
                child:'-'
              },
              {
                child1:'Товарные знаки',
                child:2
              },
              {
                child1:'Селекционные достижение',
                child:1
              },
              {
                child1:'Всего',
                child:'-'
              },
          ],
          },
          {
            parchild:[
              {
                child1:'АИС РУЗ',
                child:'Итого'
              },
              {
                child1:'Изобретения',
                child:3
              },
              {
                child1:'Полезные модели',
                child:8
              },
              {
                child1:'Промышленные образцы',
                child:'-'
              },
              {
                child1:'Товарные знаки',
                child:2
              },
              {
                child1:'Селекционные достижение',
                child:1
              },
              {
                child1:'Всего',
                child:'-'
              },
          ],

          },
      ],
  },
  {
      part:2021,
      title:'2021 Участие сотрудников АИС РУз в судебных заседаниях',
      parent:[
          {
            parchild:[
              {
                child1:'АИС РУЗ',
                child:'Ответчик'
              },
              {
                child1:'Изобретения',
                child:3
              },
              {
                child1:'Полезные модели',
                child:8
              },
              {
                child1:'Промышленные образцы',
                child:'-'
              },
              {
                child1:'Товарные знаки',
                child:2
              },
              {
                child1:'Селекционные достижение',
                child:1
              },
              {
                child1:'Всего',
                child:'-'
              },
          ],
          },
          {
              parchild:[
                  {
                    child1:'АИС РУЗ',
                    child:'Третье лицо'
                  },
                  {
                    child1:'Изобретения',
                    child:3
                  },
                  {
                    child1:'Полезные модели',
                    child:8
                  },
                  {
                    child1:'Промышленные образцы',
                    child:'-'
                  },
                  {
                    child1:'Товарные знаки',
                    child:2
                  },
                  {
                    child1:'Селекционные достижение',
                    child:1
                  },
                  {
                    child1:'Всего',
                    child:'-'
                  },
              ],
          },
          {
              parchild:[
                  {
                    childhe:'АИС РУЗ',
                    child:'Итого'
                  },
                  {
                    child:3,
                    childhe:'Изобретения'
                  },
                  {
                    child:8,
                    childhe:'Полезные модели'
                  },
                  {
                    child:2,
                    childhe:'Промышленные образцы'
                  },
                  {
                    child:6,
                    childhe:'Товарные знаки'
                  },
                  {
                    child:1,
                    childhe:'Селекционные достижение'
                  },
                  {
                    child:6,
                    childhe:'Всего'
                  },
              ],
          },
      ],
  },
]
export const ger = [
  {
    parent:[
        {
          title:"График дней приема Физических и Юридических лиц ",
            parchildhead:[
                {child:'Ф.И.О.'},
                {child:'Должность'},
                {child:'Дни и время приема'},
            ],    
            parchild:[
                {child:'Sagdullayev Baxodir Taxirovich'},
                {child:'Direktor'},
                {child:'Seshanba 10.00-12.00'},
            ],
        },
        {
            parchild:[
                {child:'Каньязов Есемурат Султамуратович'},
                {child:'Заместитель директора '},
                {child:'Вторник и Четверг, 8:00 — 11:00'},
            ],
        },
        {
            parchild:[
                {child:'Сагдуллаев Баходир Тахирович'},
                {child:'Заместитель директора '},
                {child:'Понедельник и Пятница, 8:00 — 11:00'},
            ]
        },
    ],
},
]
export const germob= [
  {
    title:"График дней приема Физических и Юридических лиц ",
    parent:[
        {
          parchild:[
            {
              child1:'Ф.И.О.',
              child:'Абдусаттаров Тўлқин Сайфуллаевич'
            },
            {
              child1:'Должность ',
              child:'Директор'
            },
            {
              child1:'Дни и время приема ',
              child:'Среда,  08:00 — 11:00'
            },
        ],
        },
        {
          parchild:[
            {
              child1:'Ф.И.О. ',
              child:'Каньязов Есемурат Султамуратович'
            },
            {
              child1:'Должность ',
              child:3
            },
            {
              child1:'Дни и время приема ',
              child:'Вторник и Четверг, 8:00 — 11:00'
            },
        ],
        },
        {
          parchild:[
            {
              child1:'Ф.И.О.',
              child:'Сагдуллаев Баходир Тахирович'
            },
            {
              child1:'Должность',
              child:'Заместитель директора '
            },
            {
              child1:'Дни и время приема ',
              child:'Понедельник и Пятница, 8:00 — 11:00'
            },
        ],
        },
    ],
},
]
export const infoCMI = [
  {
    title:"Список журналистов аккредитованных в Агентстве по интеллектуальной собственности",
    parent:[
        {
            parchildhead:[
                {child:'Ф.И.О.'},
                {child:'Место работы и должность'},
                {child:'Дата проведения аккредитации'},
                {child:'Контакты'},
            ],    
            parchild:[
                {child:'Ибодинов Улуғбек Алишерович'},
                {child:'Журналист газеты “Маҳалла” и сайта mahalladosh.uz'},
                {child:'25.06.2018'},
                {child:'+998 (71) 233 39 89 +998 (71) 233 56 83'},
            ],
        },
        {
            parchild:[
                {child:'Рыбников Роман Олегович'},
                {child:'Главный редактор журнала и ассоциации “Medical Express”'},
                {child:'25.06.2018'},
                {child:'+998 (71) 140 37 00 +998 (90) 952 37 00 +998 (90) 922 37 00'},
            ],
        },
        {
            parchild:[
                {child:'Кляцкий Григорий Николаевич'},
                {child:'Корреспондент журнала “Medical Express”'},
                {child:'25.06.2018'},
                {child:'+998 (71) 140 37 00 +998 (90) 952 37 00 +998 (90) 922 37 00'},
            ]
        },
    ],
},
]
export const infoCMImob= [
  {
    parent:[
        {
          parchild:[
            {
              child1:'Ф.И.О.',
              child:'Ибодинов Улуғбек Алишерович'
            },
            {
              child1:'Место работы и должность ',
              child:'Журналист газеты “Маҳалла” и сайта mahalladosh.uz'
            },
            {
              child1:'Дата проведения аккредитации   ',
              child:'25.06.2018'
            },
            {
              child1:'Контакты  ',
              child:'+998 (71) 233 39 89 +998 (71) 233 56 83'
            },
        ],
        },
        {
          parchild:[
            {
              child1:'Ф.И.О. ',
              child:'Рыбников Роман Олегович'
            },
            {
              child1:'Место работы и должность ',
              child:'Главный редактор журнала и ассоциации “Medical Express”'
            },
            {
              child1:'Дата проведения аккредитации   ',
              child:'25.06.2018'
            },
            {
              child1:'Контакты',
              child:'+998 (71) 140 37 00 +998 (90) 952 37 00 +998 (90) 922 37 00'
            },
        ],
        },
        {
          parchild:[
            {
              child1:'Ф.И.О.',
              child:'Кляцкий Григорий Николаевич'
            },
            {
              child1:'Место работы и должность',
              child:'Корреспондент журнала “Medical Express”'
            },
            {
              child1:'Дата проведения аккредитации   ',
              child:'25.06.2018'
            },
            {
              child1:'Контакты',
              child:'+998 (71) 140 37 00 +998 (90) 952 37 00 +998 (90) 922 37 00'
            },
        ],
        },
    ],
},
]
export const infodetail = [
  {
    parent:[
        {
            parchildhead:[
                {child:'Адрес зявки'},
                {child:'Срок заявки'},
                {child:'Телефон '},
                {child:'Электронный адрес'},
            ],    
            parchild:[
                {child:'100000, г. Ташкент, Мирзо-Улугбекский район, проспект Мустакиллик, 59'},
                {child:'с 10 января по 12 апреля 2019 года с 9.00 до 17.00 '},
                {child:'+998 (71) 232 50 32 +998 (71) 232 50 40 '},
                {child:'tanlov@ima.uz'},
            ],
        },
   ],
},
]
export const infodetailmob= [
  {
    parent:[
        {
          parchild:[
            {
              child1:'Адрес зявки.',
              child:'100000, г. Ташкент, Мирзо-Улугбекский район, проспект Мустакиллик, 59'
            },
            {
              child1:'Срок заявки ',
              child:'с 10 января по 12 апреля 2019 года с 9.00 до 17.00 '
            },
            {
              child1:'Телефон  ',
              child:'+998 (71) 232 50 32 +998 (71) 232 50 40 '
            },
            {
              child1:'Электронный адрес  ',
              child:'tanlov@ima.uz'
            },
        ],
        },
    ],
},
]
export const tabserv = [
  {
    tabinner:[
      {
        title:'Субъекты:',
        text:'Заявитель'
      },
      {
        title:'События:',
        text:'Подать заявку на использование государственных услуг через информационную систему компетентного органа или UIDXP или по почте'
      },
      {
        title:'Срок выполнения:',
        text:'По усмотрению'
      },
    ],
  },
  {
    tabinner:[
      {
        title:'Субъекты:',
        text:'Заявитель'
      },
      {
        title:'События:',
        text:'Подать заявку на использование государственных услуг через информационную систему компетентного органа или UIDXP или по почте'
      },
      {
        title:'Срок выполнения:',
        text:'По усмотрению'
      },
    ],
  },
  {
    tabinner:[
      {
        title:'Субъекты:',
        text:'Заявитель'
      },
      {
        title:'События:',
        text:'Подать заявку на использование государственных услуг через информационную систему компетентного органа или UIDXP или по почте'
      },
      {
        title:'Срок выполнения:',
        text:'По усмотрению'
      },
    ],
  },
]
export const applicaton = [
  {
    stap:[
      {
        title:'Войти в систему',
        text:[
          {
            inner:'—  Пройти регистрацию на сайте One ID '
          }
        ]
      }
    ],
  },
  {
    stap:[
      {
        title:'Войти в систему',
        text:[
          {
            inner:'Ваша роль в приложении '
          },
          {
            inner:'Изображение знака, показанное в приложении, является фотографией знака! '
          },
          {
            inner:'Руководство по типу персонажа '
          },
          {
            inner:'Изображение знака, показанное в приложении, является фотографией знака! '
          },
          {
            inner:'Руководство по типу персонажа '
          },
        ]
      }
    ],
  },
  {
    stap:[
      {
        title:'Войти в систему',
        text:[
          {
            inner:'Ваша роль в приложении'
          },
          {
            inner:'Изображение знака, показанное в приложении, является фотографией знака!'
          },
          {
            inner:'Руководство по типу персонажа'
          },
        ]
      }
    ],
  }
]
export const detail = [
  {
      maintitle:'Ведущий специалист отдела по особымпоручениям',
      inner:[
          {
              title:'Опыт работы:',
              text:'от 3 года'
          },
          {
              title:'Образование:',
              text:'Высшее ТУИТ, информационная безопасность'
          },
          {
              title:'Требования:',
              requir:[
                  {text:'Опыт работы с нестандартными документами'},
                  {text:'Пользователь ПК. Навыки работы с орг. техникой'},
                  {text:'Владение узбекским и русским языками'}
              ]
          },
          {
              title:'График работы:',
              text:'Пн – Пт 9:00 — 18:00'
          },
          {
              title:'Оплата труда:',
              text:'923 551 сум'
          },
      ]
  },
  {
      maintitle:'Ведущий специалист отдела по особымпоручениям',
      inner:[
          {
              title:'Опыт работы:',
              text:'от 3 года'
          },
          {
              title:'Образование:',
              text:'Высшее ТУИТ, информационная безопасность'
          },
          {
              title:'Требования:',
              requir:[
                  {text:'Опыт работы с нестандартными документами'},
                  {text:'Пользователь ПК. Навыки работы с орг. техникой'},
                  {text:'Владение узбекским и русским языками'}
              ]
          },
          {
              title:'График работы:',
              text:'Пн – Пт 9:00 — 18:00'
          },
          {
              title:'Оплата труда:',
              text:'923 551 сум'
          },
      ]
  },
  {
      maintitle:'Ведущий специалист отдела по особымпоручениям',
      inner:[
          {
              title:'Опыт работы:',
              text:'от 3 года'
          },
          {
              title:'Образование:',
              text:'Высшее ТУИТ, информационная безопасность'
          },
          {
              title:'Требования:',
              requir:[
                  {text:'Опыт работы с нестандартными документами'},
                  {text:'Пользователь ПК. Навыки работы с орг. техникой'},
                  {text:'Владение узбекским и русским языками'}
              ]
          },
          {
              title:'График работы:',
              text:'Пн – Пт 9:00 — 18:00'
          },
          {
              title:'Оплата труда:',
              text:'923 551 сум'
          },
      ]
  },
]
export const detailcontact = [
  {
      num:1,
      maintitle:'Можно ли подать электронную заявку на регистрацию товарного знака?',
      inner:[
          {
              title:'В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:',
              requir:[
                  {text:`Заявление о регистрации знака в качестве товарного знака, 
                  наименования места происхождения товара и права 
                  использования наименования места происхождения товара`},
                  {text:`Изображение знака, указанного в заявке`},
                  {text:`Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии 
                  с международной классификацией товаров и услуг для регистрации товарных знаков`},
                  {text:`Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление 
                  о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект`},
              ]
          },
          {
            title:'К заявлению необходимо приложить:',
            requir:[
                {text:`Подтверждение оплаты патентной пошлины за подачу заявки`},
                {text:`Доверенность заявителя в случае подачи заявки через патентного поверенного
                Документы, подтверждающие местонахождение заявителя `},
                {text:`в указанном географическом объекте и особенности производства товаров, связанные с природными условиями 
                или иными факторами, характерными для данного географического объекта, либо с природными условиями 
                и сочетанием этих факторов`},
                {text:`Документ, подтверждающий право иностранного заявителя 
                на использование наименования места происхождения товара 
                в стране происхождения товара`},
            ]
        },
      ]
  },
  {
      num:2,

      maintitle:'На что обратить внимание при подаче заявления?',
      inner:[
        {
            title:'В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:',
            requir:[
                {text:`Заявление о регистрации знака в качестве товарного знака, 
                наименования места происхождения товара и права 
                использования наименования места происхождения товара`},
                {text:`Изображение знака, указанного в заявке`},
                {text:`Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии 
                с международной классификацией товаров и услуг для регистрации товарных знаков`},
                {text:`Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление 
                о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект`},
            ]
        },
        {
          title:'К заявлению необходимо приложить:',
          requir:[
              {text:`Подтверждение оплаты патентной пошлины за подачу заявки`},
              {text:`Доверенность заявителя в случае подачи заявки через патентного поверенного
              Документы, подтверждающие местонахождение заявителя `},
              {text:`в указанном географическом объекте и особенности производства товаров, связанные с природными условиями 
              или иными факторами, характерными для данного географического объекта, либо с природными условиями 
              и сочетанием этих факторов`},
              {text:`Документ, подтверждающий право иностранного заявителя 
              на использование наименования места происхождения товара 
              в стране происхождения товара`},
          ]
      },
    ]

  },
  {
      num:2,
      maintitle:'Каков срок регистрации товарного знака?',
      inner:[
        {
            title:'В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:',
            requir:[
                {text:`Заявление о регистрации знака в качестве товарного знака, 
                наименования места происхождения товара и права 
                использования наименования места происхождения товара`},
                {text:`Изображение знака, указанного в заявке`},
                {text:`Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии 
                с международной классификацией товаров и услуг для регистрации товарных знаков`},
                {text:`Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление 
                о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект`},
            ]
        },
        {
          title:'К заявлению необходимо приложить:',
          requir:[
              {text:`Подтверждение оплаты патентной пошлины за подачу заявки`},
              {text:`Доверенность заявителя в случае подачи заявки через патентного поверенного
              Документы, подтверждающие местонахождение заявителя `},
              {text:`в указанном географическом объекте и особенности производства товаров, связанные с природными условиями 
              или иными факторами, характерными для данного географического объекта, либо с природными условиями 
              и сочетанием этих факторов`},
              {text:`Документ, подтверждающий право иностранного заявителя 
              на использование наименования места происхождения товара 
              в стране происхождения товара`},
          ]
      },
    ]

  },
  {
    num:1,
    maintitle:'Каков срок регистрации товарного знака?',
    inner:[
      {
          title:'В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:',
          requir:[
              {text:`Заявление о регистрации знака в качестве товарного знака, 
              наименования места происхождения товара и права 
              использования наименования места происхождения товара`},
              {text:`Изображение знака, указанного в заявке`},
              {text:`Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии 
              с международной классификацией товаров и услуг для регистрации товарных знаков`},
              {text:`Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление 
              о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект`},
          ]
      },
      {
        title:'К заявлению необходимо приложить:',
        requir:[
            {text:`Подтверждение оплаты патентной пошлины за подачу заявки`},
            {text:`Доверенность заявителя в случае подачи заявки через патентного поверенного
            Документы, подтверждающие местонахождение заявителя `},
            {text:`в указанном географическом объекте и особенности производства товаров, связанные с природными условиями 
            или иными факторами, характерными для данного географического объекта, либо с природными условиями 
            и сочетанием этих факторов`},
            {text:`Документ, подтверждающий право иностранного заявителя 
            на использование наименования места происхождения товара 
            в стране происхождения товара`},
        ]
    },
  ]

},
{
  num:1,
  maintitle:'Каков срок регистрации товарного знака?',
  inner:[
    {
        title:'В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:',
        requir:[
            {text:`Заявление о регистрации знака в качестве товарного знака, 
            наименования места происхождения товара и права 
            использования наименования места происхождения товара`},
            {text:`Изображение знака, указанного в заявке`},
            {text:`Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии 
            с международной классификацией товаров и услуг для регистрации товарных знаков`},
            {text:`Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление 
            о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект`},
        ]
    },
    {
      title:'К заявлению необходимо приложить:',
      requir:[
          {text:`Подтверждение оплаты патентной пошлины за подачу заявки`},
          {text:`Доверенность заявителя в случае подачи заявки через патентного поверенного
          Документы, подтверждающие местонахождение заявителя `},
          {text:`в указанном географическом объекте и особенности производства товаров, связанные с природными условиями 
          или иными факторами, характерными для данного географического объекта, либо с природными условиями 
          и сочетанием этих факторов`},
          {text:`Документ, подтверждающий право иностранного заявителя 
          на использование наименования места происхождения товара 
          в стране происхождения товара`},
      ]
  },
]

},
{
  num:3,
  maintitle:'Каков срок регистрации товарного знака?',
  inner:[
    {
        title:'В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:',
        requir:[
            {text:`Заявление о регистрации знака в качестве товарного знака, 
            наименования места происхождения товара и права 
            использования наименования места происхождения товара`},
            {text:`Изображение знака, указанного в заявке`},
            {text:`Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии 
            с международной классификацией товаров и услуг для регистрации товарных знаков`},
            {text:`Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление 
            о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект`},
        ]
    },
    {
      title:'К заявлению необходимо приложить:',
      requir:[
          {text:`Подтверждение оплаты патентной пошлины за подачу заявки`},
          {text:`Доверенность заявителя в случае подачи заявки через патентного поверенного
          Документы, подтверждающие местонахождение заявителя `},
          {text:`в указанном географическом объекте и особенности производства товаров, связанные с природными условиями 
          или иными факторами, характерными для данного географического объекта, либо с природными условиями 
          и сочетанием этих факторов`},
          {text:`Документ, подтверждающий право иностранного заявителя 
          на использование наименования места происхождения товара 
          в стране происхождения товара`},
      ]
  },
]

},
{
  num:3,
  maintitle:'Каков срок регистрации товарного знака?',
  inner:[
    {
        title:'В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:',
        requir:[
            {text:`Заявление о регистрации знака в качестве товарного знака, 
            наименования места происхождения товара и права 
            использования наименования места происхождения товара`},
            {text:`Изображение знака, указанного в заявке`},
            {text:`Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии 
            с международной классификацией товаров и услуг для регистрации товарных знаков`},
            {text:`Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление 
            о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект`},
        ]
    },
    {
      title:'К заявлению необходимо приложить:',
      requir:[
          {text:`Подтверждение оплаты патентной пошлины за подачу заявки`},
          {text:`Доверенность заявителя в случае подачи заявки через патентного поверенного
          Документы, подтверждающие местонахождение заявителя `},
          {text:`в указанном географическом объекте и особенности производства товаров, связанные с природными условиями 
          или иными факторами, характерными для данного географического объекта, либо с природными условиями 
          и сочетанием этих факторов`},
          {text:`Документ, подтверждающий право иностранного заявителя 
          на использование наименования места происхождения товара 
          в стране происхождения товара`},
      ]
  },
]

},
{
  num:4,
  maintitle:'Каков срок регистрации товарного знака?',
  inner:[
    {
        title:'В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:',
        requir:[
            {text:`Заявление о регистрации знака в качестве товарного знака, 
            наименования места происхождения товара и права 
            использования наименования места происхождения товара`},
            {text:`Изображение знака, указанного в заявке`},
            {text:`Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии 
            с международной классификацией товаров и услуг для регистрации товарных знаков`},
            {text:`Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление 
            о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект`},
        ]
    },
    {
      title:'К заявлению необходимо приложить:',
      requir:[
          {text:`Подтверждение оплаты патентной пошлины за подачу заявки`},
          {text:`Доверенность заявителя в случае подачи заявки через патентного поверенного
          Документы, подтверждающие местонахождение заявителя `},
          {text:`в указанном географическом объекте и особенности производства товаров, связанные с природными условиями 
          или иными факторами, характерными для данного географического объекта, либо с природными условиями 
          и сочетанием этих факторов`},
          {text:`Документ, подтверждающий право иностранного заявителя 
          на использование наименования места происхождения товара 
          в стране происхождения товара`},
      ]
  },
]

},
{
  num:4,
  maintitle:'Каков срок регистрации товарного знака?',
  inner:[
    {
        title:'В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:',
        requir:[
            {text:`Заявление о регистрации знака в качестве товарного знака, 
            наименования места происхождения товара и права 
            использования наименования места происхождения товара`},
            {text:`Изображение знака, указанного в заявке`},
            {text:`Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии 
            с международной классификацией товаров и услуг для регистрации товарных знаков`},
            {text:`Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление 
            о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект`},
        ]
    },
    {
      title:'К заявлению необходимо приложить:',
      requir:[
          {text:`Подтверждение оплаты патентной пошлины за подачу заявки`},
          {text:`Доверенность заявителя в случае подачи заявки через патентного поверенного
          Документы, подтверждающие местонахождение заявителя `},
          {text:`в указанном географическом объекте и особенности производства товаров, связанные с природными условиями 
          или иными факторами, характерными для данного географического объекта, либо с природными условиями 
          и сочетанием этих факторов`},
          {text:`Документ, подтверждающий право иностранного заявителя 
          на использование наименования места происхождения товара 
          в стране происхождения товара`},
      ]
  },
]

},
{
  num:4,
  maintitle:'Каков срок регистрации товарного знака?',
  inner:[
    {
        title:'В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:',
        requir:[
            {text:`Заявление о регистрации знака в качестве товарного знака, 
            наименования места происхождения товара и права 
            использования наименования места происхождения товара`},
            {text:`Изображение знака, указанного в заявке`},
            {text:`Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии 
            с международной классификацией товаров и услуг для регистрации товарных знаков`},
            {text:`Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление 
            о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект`},
        ]
    },
    {
      title:'К заявлению необходимо приложить:',
      requir:[
          {text:`Подтверждение оплаты патентной пошлины за подачу заявки`},
          {text:`Доверенность заявителя в случае подачи заявки через патентного поверенного
          Документы, подтверждающие местонахождение заявителя `},
          {text:`в указанном географическом объекте и особенности производства товаров, связанные с природными условиями 
          или иными факторами, характерными для данного географического объекта, либо с природными условиями 
          и сочетанием этих факторов`},
          {text:`Документ, подтверждающий право иностранного заявителя 
          на использование наименования места происхождения товара 
          в стране происхождения товара`},
      ]
  },
]

},
{
  num:5,
  maintitle:'Каков срок регистрации товарного знака?',
  inner:[
    {
        title:'В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:',
        requir:[
            {text:`Заявление о регистрации знака в качестве товарного знака, 
            наименования места происхождения товара и права 
            использования наименования места происхождения товара`},
            {text:`Изображение знака, указанного в заявке`},
            {text:`Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии 
            с международной классификацией товаров и услуг для регистрации товарных знаков`},
            {text:`Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление 
            о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект`},
        ]
    },
    {
      title:'К заявлению необходимо приложить:',
      requir:[
          {text:`Подтверждение оплаты патентной пошлины за подачу заявки`},
          {text:`Доверенность заявителя в случае подачи заявки через патентного поверенного
          Документы, подтверждающие местонахождение заявителя `},
          {text:`в указанном географическом объекте и особенности производства товаров, связанные с природными условиями 
          или иными факторами, характерными для данного географического объекта, либо с природными условиями 
          и сочетанием этих факторов`},
          {text:`Документ, подтверждающий право иностранного заявителя 
          на использование наименования места происхождения товара 
          в стране происхождения товара`},
      ]
  },
]

},
{
  num:6,
  maintitle:'Каков срок регистрации товарного знака?',
  inner:[
    {
        title:'В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:',
        requir:[
            {text:`Заявление о регистрации знака в качестве товарного знака, 
            наименования места происхождения товара и права 
            использования наименования места происхождения товара`},
            {text:`Изображение знака, указанного в заявке`},
            {text:`Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии 
            с международной классификацией товаров и услуг для регистрации товарных знаков`},
            {text:`Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление 
            о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект`},
        ]
    },
    {
      title:'К заявлению необходимо приложить:',
      requir:[
          {text:`Подтверждение оплаты патентной пошлины за подачу заявки`},
          {text:`Доверенность заявителя в случае подачи заявки через патентного поверенного
          Документы, подтверждающие местонахождение заявителя `},
          {text:`в указанном географическом объекте и особенности производства товаров, связанные с природными условиями 
          или иными факторами, характерными для данного географического объекта, либо с природными условиями 
          и сочетанием этих факторов`},
          {text:`Документ, подтверждающий право иностранного заявителя 
          на использование наименования места происхождения товара 
          в стране происхождения товара`},
      ]
  },
]

},
{
  num:6,
  maintitle:'Каков срок регистрации товарного знака?',
  inner:[
    {
        title:'В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:',
        requir:[
            {text:`Заявление о регистрации знака в качестве товарного знака, 
            наименования места происхождения товара и права 
            использования наименования места происхождения товара`},
            {text:`Изображение знака, указанного в заявке`},
            {text:`Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии 
            с международной классификацией товаров и услуг для регистрации товарных знаков`},
            {text:`Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление 
            о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект`},
        ]
    },
    {
      title:'К заявлению необходимо приложить:',
      requir:[
          {text:`Подтверждение оплаты патентной пошлины за подачу заявки`},
          {text:`Доверенность заявителя в случае подачи заявки через патентного поверенного
          Документы, подтверждающие местонахождение заявителя `},
          {text:`в указанном географическом объекте и особенности производства товаров, связанные с природными условиями 
          или иными факторами, характерными для данного географического объекта, либо с природными условиями 
          и сочетанием этих факторов`},
          {text:`Документ, подтверждающий право иностранного заявителя 
          на использование наименования места происхождения товара 
          в стране происхождения товара`},
      ]
  },
]

},
{
  num:6,
  maintitle:'Каков срок регистрации товарного знака?',
  inner:[
    {
        title:'В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:',
        requir:[
            {text:`Заявление о регистрации знака в качестве товарного знака, 
            наименования места происхождения товара и права 
            использования наименования места происхождения товара`},
            {text:`Изображение знака, указанного в заявке`},
            {text:`Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии 
            с международной классификацией товаров и услуг для регистрации товарных знаков`},
            {text:`Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление 
            о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект`},
        ]
    },
    {
      title:'К заявлению необходимо приложить:',
      requir:[
          {text:`Подтверждение оплаты патентной пошлины за подачу заявки`},
          {text:`Доверенность заявителя в случае подачи заявки через патентного поверенного
          Документы, подтверждающие местонахождение заявителя `},
          {text:`в указанном географическом объекте и особенности производства товаров, связанные с природными условиями 
          или иными факторами, характерными для данного географического объекта, либо с природными условиями 
          и сочетанием этих факторов`},
          {text:`Документ, подтверждающий право иностранного заявителя 
          на использование наименования места происхождения товара 
          в стране происхождения товара`},
      ]
  },
]

},
{
  num:6,
  maintitle:'Каков срок регистрации товарного знака?',
  inner:[
    {
        title:'В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:',
        requir:[
            {text:`Заявление о регистрации знака в качестве товарного знака, 
            наименования места происхождения товара и права 
            использования наименования места происхождения товара`},
            {text:`Изображение знака, указанного в заявке`},
            {text:`Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии 
            с международной классификацией товаров и услуг для регистрации товарных знаков`},
            {text:`Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление 
            о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект`},
        ]
    },
    {
      title:'К заявлению необходимо приложить:',
      requir:[
          {text:`Подтверждение оплаты патентной пошлины за подачу заявки`},
          {text:`Доверенность заявителя в случае подачи заявки через патентного поверенного
          Документы, подтверждающие местонахождение заявителя `},
          {text:`в указанном географическом объекте и особенности производства товаров, связанные с природными условиями 
          или иными факторами, характерными для данного географического объекта, либо с природными условиями 
          и сочетанием этих факторов`},
          {text:`Документ, подтверждающий право иностранного заявителя 
          на использование наименования места происхождения товара 
          в стране происхождения товара`},
      ]
  },
]

},
{
  num:7,
  maintitle:'Каков срок регистрации товарного знака?',
  inner:[
    {
        title:'В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:',
        requir:[
            {text:`Заявление о регистрации знака в качестве товарного знака, 
            наименования места происхождения товара и права 
            использования наименования места происхождения товара`},
            {text:`Изображение знака, указанного в заявке`},
            {text:`Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии 
            с международной классификацией товаров и услуг для регистрации товарных знаков`},
            {text:`Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление 
            о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект`},
        ]
    },
    {
      title:'К заявлению необходимо приложить:',
      requir:[
          {text:`Подтверждение оплаты патентной пошлины за подачу заявки`},
          {text:`Доверенность заявителя в случае подачи заявки через патентного поверенного
          Документы, подтверждающие местонахождение заявителя `},
          {text:`в указанном географическом объекте и особенности производства товаров, связанные с природными условиями 
          или иными факторами, характерными для данного географического объекта, либо с природными условиями 
          и сочетанием этих факторов`},
          {text:`Документ, подтверждающий право иностранного заявителя 
          на использование наименования места происхождения товара 
          в стране происхождения товара`},
      ]
  },
]

},
{
  num:7,
  maintitle:'Каков срок регистрации товарного знака?',
  inner:[
    {
        title:'В соответствии со статьей 9 Закона «О товарных знаках, знаках обслуживания и наименованиях мест происхождения товаров» заявка должна содержать:',
        requir:[
            {text:`Заявление о регистрации знака в качестве товарного знака, 
            наименования места происхождения товара и права 
            использования наименования места происхождения товара`},
            {text:`Изображение знака, указанного в заявке`},
            {text:`Перечень товаров, в отношении которых испрашивается регистрация товарных знаков, сгруппированных в соответствии 
            с международной классификацией товаров и услуг для регистрации товарных знаков`},
            {text:`Наименование места происхождения товара или наименование вида товара, в отношении которого подается заявление 
            о регистрации права на использование наименования места происхождения товара, с указанием места происхождения товара в пределах географический объект`},
        ]
    },
    {
      title:'К заявлению необходимо приложить:',
      requir:[
          {text:`Подтверждение оплаты патентной пошлины за подачу заявки`},
          {text:`Доверенность заявителя в случае подачи заявки через патентного поверенного
          Документы, подтверждающие местонахождение заявителя `},
          {text:`в указанном географическом объекте и особенности производства товаров, связанные с природными условиями 
          или иными факторами, характерными для данного географического объекта, либо с природными условиями 
          и сочетанием этих факторов`},
          {text:`Документ, подтверждающий право иностранного заявителя 
          на использование наименования места происхождения товара 
          в стране происхождения товара`},
      ]
  },
]

},
]