import React, { useRef, useEffect } from 'react'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import classes from './ActivityCorruption.module.scss';

function ActivityCorruption() {
    const ref = useRef(null)
    const navigate = useNavigate()

    useEffect(() => {
        navigate('/activity-corruption/message')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClick = () => {
        ref.current.classList = ref.current.classList[0]
    }

    const handleCustomClick = () => {
        // eslint-disable-next-line no-useless-concat
        ref.current.classList = ref.current.classList[0] + ' ' + 'active'
    }

    return (
    <div className={` ${classes.activity__corruption} load-anim`}>
            <BreadCrumb
                list={[
                    { title: "Bosh sahifa", path: "/" },
                    { title: "Faoliyat", path: "/activity" },
                    { title: "Korrupsiyaga qarshi kurash  ", path: "/activity-corruption/message" },
                  ]}
            />

            <div className={`container ${classes.activity__corruption__inner}`}>
                <h1 className={classes.activity__corruption__title}>
                    Korrupsiyaga qarshi kurash
                </h1>

                <p>Keyingi yillarda mamlakatimizda korrupsiyaga qarshi kurashish sohasida muhim tashkiliy-huquqiy islohotlar amalga oshirildi. Aholining huquqiy ongi va huquqiy madaniyatini oshirishga, jamiyatda korrupsiyaga qarshi munosabatni shakllantirishga yo‘naltirilgan tizimli choralar ko‘rildi.</p>
                <p style={{marginTop: 20}}>Prezidentimiz Shavkat Mirziyoyevning Oliy Majlis palatalariga Murojaatnomasida “Bu yovuz baloning oldini olmasak, haqiqiy ishbilarmonlik va investitsiya muhitini yaratib bo‘lmaydi, umuman jamiyatning birorta tarmog‘i rivojlanmaydi”, deb ta’kidladilar.</p>
                <p style={{marginTop: 20}}>Ayniqsa, vazifalar ijrosini tizimli tashkil etish maqsadida parlament va Prezidentga hisob beradigan, korrupsiyaga qarshi kurashishga mas’ul bo‘ladigan alohida organ tashkil etish haqidagi taklif bu boradagi ishlarni tizimli yo‘lga qo‘yishni ta’minlaydi.</p>
                <p style={{marginTop: 20}}>Shu ma’noda, mamlakatimizda korrupsiyaga qarshi kurashning muvaffaqiyati, birinchi navbatda, har birimizning daxldorlik hissi bilan yondashishimiz va chuqur mas’uliyat bilan idrok etishimizga bog‘liq.</p>
                <p style={{marginTop: 20}}>Adliya vazirligi huzuridagi Intellektual mulk markazi tomonidan ushbu illatga qarshi kurashish bo‘yicha kompleks chora-tadbirlar hamda korrupsiyani oldini olish bo‘yicha ish rejasi ishlab chiqilgan. Markaz yurtboshimiz Murojaatnomada qayd etganlaridek, barcha sa’y-harakatlarini korrupsiyaga qarshi profilaktika ishlariga jiddiy e’tibor qaratib, xodimlarni halollikka rioya qilish bo‘yicha qat’iy tartib o‘rnatgan.</p>
                <p style={{marginTop: 20}}>Jumladan, tizimda korrupsiya xavfini baholab borish, xodimlar o‘rtasida korrupsiyaga qarshi murosasiz kurash olib borish yuzasidan tushuntirish ishlari olib borish, ularni ichki me’yoriy hujjatlar bilan tanishtirib, ogohligini oshirish, informatsion texnologiyalarni qo‘llash orqali samarali nazoratni o‘rnatish bo‘yicha jiddiy e’tibor qaratilmoqda.</p>
                <p style={{marginTop: 20}}>Biz jamiyatda huquqiy ong va huquqiy madaniyatni yuksaltirish borasidagi ishlarimizni uzluksiz davom ettirishimiz zarur. Huquqiy tafakkurni rivojlantirmas ekanmiz korrupsiyaga kurashning har qanday vositasi samarasiz bo‘lib qolaveradi.</p>
                <p style={{marginTop: 20}}>Korrupsiyaga befarqlik bilan qarash uning ildiz otishiga sabab bo‘ladi. Bu illatga qarshi kurashni o‘zimizdan boshlashimiz zarur. Shundagina biz jamiyatda qonun ustuvorligini ta’minlashga, fuqarolarning qonun va davlat idoralariga ishonchini mustahkamlash, eng yuqori darajadagi qulay investitsiyaviy va ishbilarmonlik muhitini yaratish, mamlakatning xalqaro maydondagi ijobiy imijini oshirishga erishamiz.</p>
                <p style={{marginTop: 20}}>Men sohada ochiqlik va oshkoralikni ta’minlash, korrupsiyaga qarshi kurashning xalqaro andozalarga javob beradigan islohotlarni bundan buyon ham qat’iy davom ettirish tarafdoriman va Sizlarni ham bunga amal qilishingiz ustuvor maqsad hisoblanadi.</p>
                <p style={{marginTop: 20}}>Intellektual mulk markazi korrupsiyaga qarshi kurashish va uni oldini olishda birinchi navbatda, fuqarolar faolligiga va tashabbuslarini qo‘llab-quvvatlaydi.</p>
                <p style={{marginTop: 20}}>Markaz barcha fuqarolar va xodimlarni korrupsiyaga qarshi kurashga chaqirib, korrupsiya bilan bog‘liq holatlar kuzatilgan taqdirda o‘z ariza va shikoyatlarni info@ima.uz elektron pochtasiga yuborish yoki (71) 232-50-54 ishonch telefoniga murojaat qilishlarini so‘rab qolamiz.</p>
                <p style={{marginTop: 20}}>Prezidentimiz 2019 yil 27 may kuni imzolagan “O‘zbekistonda korrupsiyaga qarshi kurashish tizimini yanada takomillashtirish to‘g‘risida”gi Farmonga binoan davlat organlari o‘z zimmalariga yuklangan vazifa va funksiyalarni amalga oshirishda yuzaga keladigan korrupsiya xavf-xatarlarini vaqti-vaqti bilan majburiy ravishda baholab borishlari lozim. Agar davlat boshqaruvi va mahalliy ijro etuvchi hokimiyat organlari rahbarlari korrupsiya xavf-xatarlarini o‘z vaqtida aniqlamasalar hamda tegishli baho bermasalar, shuningdek, korrupsiyaga oid huquqbuzarliklarni takroran sodir etish holatlariga yo‘l qo‘ysalar, shaxsan javobgar bo‘lishlari belgilangan.</p>
                <p style={{marginTop: 20}}>Shundan kelib chiqib, tizim xodimlari o‘rtasida korrupsiyaga qarshi yalpi murosasiz kurash e’lon qilishni taklif qilaman.</p>
                <p style={{marginTop: 20}}>Tizimda mehnat qilayotganlarni yana bir bor halollik, xolislikni ta’minlashga, adolat va haqiqat yo‘lida qat’iyatli bo‘lishga chaqiraman. Zero har qanday yuksak ahloq va ma’naviyat hukm surgan davlat yanada obod va farovon bo‘ladi.</p>
                {/* <div className={classes.activity__corruption__nav__wrapper}>
                    <div className={classes.activity__corruption__navigation}>
                        <NavLink
                            ref={ref}
                            to='/activity-corruption/message'
                            onClick={handleCustomClick}
                            className={classes.activity__corruption__nav}
                        >
                            Обращение директора
                        </NavLink>

                        <NavLink
                            onClick={handleClick}
                            to='/activity-corruption/regulations'
                            className={classes.activity__corruption__nav}
                        >
                            Нормативно-правовые акты
                        </NavLink>

                        <NavLink
                            to='/activity-corruption/provide'
                            className={classes.activity__corruption__nav}
                        >
                            принятие меры по противодействию коррупции
                        </NavLink>
                    </div>
                </div> */}
                {/* <Outlet /> */}
            </div>
        </div>
    )
}

export default ActivityCorruption