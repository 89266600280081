import "./icon.css"
export const Utube = () => {
    return (
      <svg className="icon" width="25" height="18" viewBox="0 0 25 18"  xmlns="http://www.w3.org/2000/svg">
          
<path d="M24.4849 2.8167C24.3419 2.27413 24.0633 1.77949 23.6768 1.38195C23.2902 0.984412 22.8092 0.697824 22.2816 0.55067C20.3254 0.000244141 12.4998 0.000244141 12.4998 0.000244141C12.4998 0.000244141 4.6745 0.000244141 2.71829 0.529762C1.66804 0.826083 0.802349 1.71545 0.514958 2.8167C0 4.82823 0 8.99964 0 8.99964C0 8.99964 0 13.1924 0.514958 15.183C0.657853 15.7257 0.936423 16.2205 1.32297 16.6182C1.70951 17.0159 2.19056 17.3026 2.71829 17.4498C4.69523 18.0002 12.5001 18.0002 12.5001 18.0002C12.5001 18.0002 20.3254 18.0002 22.2816 17.4707C22.8093 17.3237 23.2903 17.0372 23.6769 16.6397C24.0635 16.2422 24.3422 15.7476 24.4853 15.2051C24.9999 13.1948 24.9999 9.02216 24.9999 9.02216C24.9999 9.02216 25.0206 4.82823 24.4849 2.8167ZM10.0098 12.8534V5.14666L16.517 9.00045L10.0098 12.8534Z" />
</svg>
  
    )
}

export const Telegram = () => {
    return (
      <svg  className="icon" width="19" height="16" viewBox="0 0 19 16"  xmlns="http://www.w3.org/2000/svg">
<path d="M0.335633 7.67111L4.71339 9.24908L6.40826 14.5202C6.43272 14.5985 6.47675 14.6698 6.53647 14.7278C6.59618 14.7857 6.66973 14.8285 6.75063 14.8525C6.83152 14.8764 6.91726 14.8807 7.00027 14.8649C7.08327 14.8492 7.16099 14.8139 7.22655 14.7622L9.66751 12.8381C9.79196 12.7403 9.94634 12.6849 10.1067 12.6806C10.267 12.6762 10.4243 12.7232 10.5542 12.8142L14.956 15.9052C15.0258 15.9541 15.107 15.9853 15.1924 15.9961C15.2778 16.0068 15.3647 15.9967 15.445 15.9667C15.5253 15.9367 15.5966 15.8877 15.6524 15.8242C15.7081 15.7607 15.7466 15.6848 15.7642 15.6032L18.9892 0.600855C19.0081 0.513031 19.0021 0.421923 18.9719 0.337107C18.9418 0.252292 18.8885 0.176902 18.8178 0.118862C18.7471 0.0608221 18.6616 0.0222761 18.5701 0.00727571C18.4787 -0.00772463 18.3848 0.00137476 18.2983 0.0336174L0.330566 6.73756C0.232832 6.77398 0.148868 6.83831 0.0898258 6.92198C0.0307837 7.00565 -0.000542698 7.10472 7.11522e-06 7.20602C0.000556929 7.30732 0.0329569 7.40607 0.0929041 7.48914C0.152851 7.57221 0.237509 7.63567 0.335633 7.67111V7.67111ZM6.13528 8.41048L14.6938 3.3133C14.7254 3.29525 14.7626 3.28903 14.7986 3.29582C14.8346 3.30261 14.8667 3.32194 14.8889 3.35013C14.9111 3.37833 14.9218 3.41342 14.919 3.44876C14.9162 3.48409 14.9001 3.5172 14.8737 3.54179L7.80987 9.89044C7.56201 10.1136 7.40176 10.413 7.35639 10.7376L7.11571 12.4614C7.10918 12.5102 7.0854 12.5554 7.04841 12.5893C7.01141 12.6231 6.96346 12.6436 6.91266 12.6472C6.86187 12.6508 6.81135 12.6373 6.76964 12.609C6.72793 12.5808 6.6976 12.5394 6.68377 12.492L5.75906 9.3477C5.70757 9.17318 5.71645 8.98715 5.78437 8.81796C5.85228 8.64877 5.97551 8.50567 6.13528 8.41048V8.41048Z" />
</svg>

  
    )
}


export const Facebook = () => {
    return (
      <svg  className="icon" width="9" height="18" viewBox="0 0 9 18"  xmlns="http://www.w3.org/2000/svg">
<path d="M5.84173 18.0002V9.79042H8.49057L8.89275 6.58934H5.84173V4.54616C5.84173 3.61797 6.08863 2.98913 7.36892 2.98913H9V0.125668C8.21067 0.0395404 7.41743 -0.0022889 6.62376 0.000362696C4.27098 0.000362696 2.66 1.49126 2.66 4.22942V6.58934H0V9.79042H2.66V18.0002H5.84173Z" />
</svg>

  
    )
}


export const Instagram = () => {
    return (
     <svg  className="icon" width="20" height="20" viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg">
<path d="M10.0028 4.86426C7.16693 4.86426 4.87109 7.16259 4.87109 9.99592C4.87109 12.8318 7.16943 15.1276 10.0028 15.1276C12.8386 15.1276 15.1344 12.8293 15.1344 9.99592C15.1344 7.16009 12.8361 4.86426 10.0028 4.86426V4.86426ZM10.0028 13.3268C8.16193 13.3268 6.67193 11.8359 6.67193 9.99592C6.67193 8.15592 8.16276 6.66509 10.0028 6.66509C11.8428 6.66509 13.3336 8.15592 13.3336 9.99592C13.3344 11.8359 11.8436 13.3268 10.0028 13.3268V13.3268Z" />
<path d="M14.1235 0.0628222C12.2835 -0.0230111 7.72602 -0.0188445 5.88436 0.0628222C4.26602 0.138656 2.83852 0.529489 1.68769 1.68032C-0.235644 3.60366 0.0101894 6.19532 0.0101894 9.99616C0.0101894 13.8862 -0.206477 16.4178 1.68769 18.312C3.61852 20.242 6.24769 19.9895 10.0035 19.9895C13.8569 19.9895 15.1869 19.992 16.5494 19.4645C18.4019 18.7453 19.8002 17.0895 19.9369 14.1153C20.0235 12.2745 20.0185 7.71782 19.9369 5.87616C19.7719 2.36532 17.8877 0.236156 14.1235 0.0628222V0.0628222ZM17.036 17.0395C15.7752 18.3003 14.026 18.1878 9.97936 18.1878C5.81269 18.1878 4.14186 18.2495 2.92269 17.027C1.51852 15.6295 1.77269 13.3853 1.77269 9.98282C1.77269 5.37866 1.30019 2.06282 5.92102 1.82616C6.98269 1.78866 7.29519 1.77616 9.96769 1.77616L10.0052 1.80116C14.446 1.80116 17.9302 1.33616 18.1394 5.95616C18.1869 7.01032 18.1977 7.32699 18.1977 9.99532C18.1969 14.1137 18.2752 15.7945 17.036 17.0395V17.0395Z" />
<path d="M15.3378 5.86098C16.0001 5.86098 16.537 5.3241 16.537 4.66181C16.537 3.99953 16.0001 3.46265 15.3378 3.46265C14.6756 3.46265 14.1387 3.99953 14.1387 4.66181C14.1387 5.3241 14.6756 5.86098 15.3378 5.86098Z" />
</svg>

  
    )
}