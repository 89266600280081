/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import classes from "./PatentFund.module.scss";
import FundImage from "../../../assets/images/fund.png";
import PatentFundItem from "../../../components/PatentFundItem/PatentFundItem";
import { useFetch } from "../../../hooks/useFetch";
import parse from "html-react-parser";
import Loader from "../../../containers/Loader";
function PatentFund() {
  const [loading, data, error] = useFetch(`/content/one/page?lang=ru&id=46`);
  if (loading) return <Loader />;

  return (
    <div className={` ${classes.patent__fund} load-anim`}>
      <BreadCrumb
        list={[
          { title: "Bosh sahifa", path: "/" },
          { title: "Muassasa", path: "/agency" },
          {
            title: "Davlat patent fondi",
            path: "/patent-fund",
          },
        ]}
      />
      <div className={`container ${classes.patent__fund__inner}`}>
        <h2 className={classes.patent__fund__title}>{data.data?.title}</h2>

        <div className={classes.patent__fund__wrapper}>
          <div className={classes.patent__fund__left}>
            <img
              src={data.data?.image}
              alt="fund-image"
              width="500"
              height="400"
              className={classes.patent__fund__img}
            />
          </div>

          <div className={classes.patent__fund__right}>
            {data.data && data.data ? (
              <p className={classes.patent__fund__text}>
                {parse(data.data.description)}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatentFund;
