import React from "react";
import classes from './Signs.module.scss'
import parse from 'html-react-parser'
export default function Signs({ obj }) {
    return (
        <div className={classes.ServicesInventionsSigns}>
            {
                obj.map((elem, i) => (
                    <div key={i} className={classes.ServicesInventionsSigns_inner}>
                        <p>{parse(elem.description)}</p>
                        {/* <div className={classes.ServicesInventionsSigns_inner_middle}>
                            <h2>Следующие товарные знаки не проходит регистрацию</h2>
                            <ul>
                                <li>Государственные гербы, флаги и изображения государственных наград</li>
                                <li>Официальные названия стран, сокращенные или полные названия международных или межправительственных организаций; Знаки, состоящие полностью или частично из флагов, гербов или иных эмблем государств, правительств или официально признанных международных или межправительственных организаций, могут быть зарегистрированы в качестве товарных знаков с согласия соответствующего государственного органа. При этом эмблемы и наименования международных (межправительственных) организаций могут быть зарегистрированы для регистрации только по заявлению этих организаций. Это также относится к имитации персонажей, показанных</li>
                                <li>Официальный осмотр, гарантийные штампы и образцы штампов, пломбы. Штампы и печати используются для обозначения товаров, отвечающих определенным условиям, особенно техническим, независимо от их производителя. Этикетки с указанием содержания исходных металлов в товаре и т.п. является примером такого персонажа. Такие обозначения также могут быть включены в состав товарного знака в качестве неохраняемых элементов, но только с согласия соответствующего компетентного органа.</li>
                                <li>Изображения отличительных и отличительных знаков государственных услуг, используемых в Республике Узбекистан. Если изображения товарных знаков выступают в качестве единственного или ведущего элемента товарного знака, то они не регистрируются в качестве товарных знаков. В случае включения таких изображений в знак заявки заявитель должен предоставить документ, подтверждающий право на их использование.</li>
                            </ul>
                        </div>
                        <div className={classes.ServicesInventionsSigns_inner_middle}>
                            <h2>Следующие товарные знаки не проходит регистрацию</h2>
                            <ul>
                                <li>Отдельные буквы, не имеющие определенного графического изображения, символы, состоящие из цифр, буквосочетания, не имеющие словоформы;</li>
                                <li>Линии, простые геометрические фигуры и комбинации, не образующие композиций, отличающихся от своих отдельных элементов, дающих качественно иной уровень восприятия;</li>
                                <li>Фактические или схематические изображения товаров в заявке на регистрацию в качестве товарного знака;</li>
                                <li>Объемные объекты, форма которых зависит только от функциональной функции;</li>
                                <li>Общепризнанные общеупотребительные наименования, используемые для наименования заявленных товаров;</li>
                                <li>Общепринятые аббревиатуры организаций, предприятий и отраслей и их сокращения.</li>
                                <li>Знаки, находящиеся в общем употреблении в качестве обозначения определенного вида товаров. Он относится к этикетке, которая используется для конкретного продукта, и такая этикетка начинает выражать понятие о типе в связи с тем, что она используется в течение длительного времени для конкретного продукта или одного и того же типа продукта разными производители. Примерами таких символов являются: целлофан, термос, ланолин, вазелин, сахарин, целлулоид, эскалатор, примус, патефон, магнитофон, линолеум, нейлон и др.;
                                    <ul>
                                        <li className={classes.inner} >—  Машиностроение - зубчатое</li>
                                        <li className={classes.inner} >—  Xимико-фармацевтическая промышленность - трубы, бензольное кольцо</li>
                                        <li className={classes.inner} >—  Мясная и молочная промышленность - крупный рогатый скот, головы животных</li>
                                        <li className={classes.inner} >—  Медицина - змея и чаша и другие</li>
                                    </ul>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                ))
            }

        </div>

    )


}